'use strict';

(function (window, $) {
    var state = {
        'tab': $('input[name=tabgroup]:checked').val()
    };

    $(document).on('click', '.jwsdw-tabs-ism-nav-item-button', function() {
        var tabs,
            buttons,
            activeTab = parseInt(this.getAttribute('for').slice(-1)) - 1;

        state.tab = this.innerText;

        tabs = $('.' + this.getAttribute('name') + '.jwsdw-tabs-ism-content-item');
        buttons = $('.' + this.getAttribute('name') + '.jwsdw-tabs-ism-nav-item-button');

        for (let i = 0; i < tabs.length; i++) {
            if (i === activeTab) {
                tabs[i].classList.add('jwsdw-tabs-ism-activeTab');
                buttons[i].classList.add('jwsdw-tabs-ism-activeButton');
            } else {
                tabs[i].classList.remove('jwsdw-tabs-ism-activeTab');
                buttons[i].classList.remove('jwsdw-tabs-ism-activeButton');
            }
        }
    });

    /**
     * @description Method to build Url Parameters from obj
     * @param {Object} obj the object
     * @returns {String} url with url parameters
     */
    function buildUrlParameters(obj) { //eslint-disable-line
        var url = '',
            params = [];

        params = Object.keys(obj)
            .filter(function(key) {
                return obj[key];
            })
            .sort(function(a, b) {
                return a > b ? 1 : -1;
            })
            .map(function(key) {
                return key + '=' + obj[key];
            });
        if (params.length > 0) {
            url = '?' + params.join('&');
        }
        return url;
    }
}(window, jQuery));