'use strict';

(function (angular) {
    promotionService.$inject = ['$q', 'ocapiPromotionService'];
    angular
        .module('jwsdw.services')
        .factory('promotionService', promotionService);

    /**
     * @class jwsdwServices.productService
     * @description Service to provide access to the product information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} ocapiPromotionService service to provide access to the ocapi promotion resource
     * @returns {Object} service object that returns public methods
     */
    function promotionService($q, ocapiPromotionService) {
        var _cachedPromotionData = {},
            service;

        service = {
            'getPromotion': getPromotion
        };

        return service;

        /**
         * @description Method to get promotion data. Received data is cached in local variable.
         * @param {String} promotionId id for the promotion which is requested
         * @returns {Promise} promise with the parsed promotion data
         */
        function getPromotion(promotionId) {
            var defer = $q.defer(),
                parsedPromotionData;

            if (_cachedPromotionData.hasOwnProperty(promotionId)) {
                defer.resolve(_cachedPromotionData[promotionId]);
            } else {
                ocapiPromotionService.getPromotion(promotionId).then(function (promotionData) {
                    parsedPromotionData = _parsePromotionData(promotionData);
                    _cachedPromotionData[promotionId] = parsedPromotionData;
                    defer.resolve(parsedPromotionData);
                });
            }

            return defer.promise;
        }

        /**
         * @description Method to parse raw promotion data received from the ocapi.
         * @param {Object} promotionData promotion data
         * @param {String} promotionData.callout_msg callout message
         * @param {String} promotionData.c_promoTag promo tag name
         * @returns {Object} parsed promotion data
         * @private
         */
        function _parsePromotionData(promotionData) {
            return {
                'callOutMessage': promotionData.callout_msg,
                'promoTagName': promotionData.c_promoTag || promotionData.callout_msg
            };
        }
    }
}(angular));