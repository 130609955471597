'use strict';

(function (angular) {
    SizeGuideSizeSelectController.$inject = ['$scope', 'SIZE_SORTING_ORDER'];
    angular
        .module('jwsdw.product')
        .directive('jwsdwSizeGuideSizeSelect', jwsdwSizeGuideSizeSelect);

    /**
     * @class jwsdwProduct.jwsdwSizeGuideSizeSelect
     * @description Size Guide
     * @returns {Object} directive object
     */
    function jwsdwSizeGuideSizeSelect() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'sizeGuideSizeSelect.html',
            'replace': true,
            'scope': {
                'sizes': '=jwsdwSizeGuideSizeSelect',
                'selectedSizes': '=jwsdwSelectedSizes',
                'type': '=jwsdwType',
                'selectSize': '=jwsdwSelectSize'
            },
            'controller': SizeGuideSizeSelectController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwSizeGuideSizeSelect.SizeGuideSizeSelectController
     * @description Controller Size Guide Size Select
     * @param {Object} $scope current scope
     * @param {Object} SIZE_SORTING_ORDER size ids in sorting order
     * @returns {void}
     */
    function SizeGuideSizeSelectController($scope, SIZE_SORTING_ORDER) {
        var vm = this;

        this.$onInit = function () {
            $scope.$watch('vm.sizes', function() {
                Object.keys(vm.sizes).forEach(function(type) {
                    vm.sizes[type] = vm.sizes[type]
                        .map(function(size) {
                            return typeof size === 'string' ?
                                {
                                    'id': size,
                                    'value': size.replace('SHORT SIZE ', '')
                                } :
                                size;
                        })
                        .sort(function(a, b) {
                            if (SIZE_SORTING_ORDER.indexOf(a.value) !== -1 &&
                                SIZE_SORTING_ORDER.indexOf(b.value) !== -1) {
                                return SIZE_SORTING_ORDER.indexOf(a.value) > SIZE_SORTING_ORDER.indexOf(b.value) ? 1 : -1;
                            }
                            return type !== 'regular' ? Number(b.value) - Number(a.value) : Number(a.value) - Number(b.value);
                        });
                });
            });
        };
    }
}(angular));