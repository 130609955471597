'use strict';

(function (angular) {

    VariantsPickerController.$inject = ['$scope', '$timeout', 'productService', 'SIZE_SORTING_ORDER'];
    angular
        .module('jwsdw.picker')
        .controller('VariantsPickerController', VariantsPickerController);

    /**
     * @class jwsdwBasket.VariantsPickerController
     * @description Handles the request for getting variant information and showing the respective picker
     * @param {Object} $scope current scope
     * @param {Object} $timeout timeout service provided through angular
     * @param {Object} productService service to provide product information (used for creation of variants item)
     * @param {Object} SIZE_SORTING_ORDER size sorting array
     * @returns {Object} service object that returns public methods
     */
    function VariantsPickerController($scope, $timeout, productService, SIZE_SORTING_ORDER) {
        var vm = this;

        vm.productService = productService;

        vm.variants = null;
        vm.variationDataReady = false;
        vm.colorNames = {};
        vm.selectedSize = null;
        vm.selectedColor = null;
        vm.isBonus = false;

        vm.hasSizes = hasSizes;
        vm.colorName = colorName;
        vm.getFirstVariantByColorId = getFirstVariantByColorId;
        vm.getVariantById = getVariantById;
        vm.getQuantity = getQuantity;
        vm.updateProduct = updateProduct;

        //listener for broadcasted event 'openPicker' fired in the basket controller
        window.jwsdwMediator.subscribe('openPicker', function(type, data) {
            if (type === 'variationPicker') {
                vm.dataService = data.dataService;
                if (data.isBonus) {
                    vm.isBonus = true;
                    activateBonus(data.product, data.dataService.allBonusProductVariants());
                } else {
                    activate(data.product);
                }
            }
        });

        /* Listening to picker close event to reset the picker interface */
        window.jwsdwMediator.subscribe('afterPickerClosed', function(type) {
            if (type === 'variationPicker') {
                vm.variationDataReady = false;
            }
        });

        window.jwsdwMediator.subscribe('changeColor', function(colorId) {
            if (vm.selectedSize && colorId) {
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(vm.selectedSize.id, colorId);
                    });
                });
            }
        });
        window.jwsdwMediator.subscribe('changeSize', function(sizeId) {
            if (vm.selectedColor && sizeId) {
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(sizeId, vm.selectedColor.id);
                    });
                });
            }
        });

        /**
         * @description Method to initialize the variants
         * @param {Object} product product for which variants have to be loaded
         * @param {String} product.basketItemId basket item Id
         * @param {String} product.id product Id
         * @param {String} product.size size
         * @param {String} product.stock amount in stock
         * @param {String} product.colorId color Id
         * @returns {void}
         */
        function activate(product) {
            vm.basketItemId = product.basketItemId;
            vm.initialProductId = product.id;
            vm.variants = [];

            productService.getVariants(product).then(function (variants) {
                parseProductData(variants, product);
            });
        }

        /**
         * @description Method to initialize the bonus product choices
         * @param {Object} product bonus product for which variants have to be loaded
         * @param {Array} products list of product id's that should be shown in picker
         * @returns {void}
         */
        function activateBonus(product, products) {
            vm.basketItemId = product.basketItemId;
            vm.initialProductId = product.id;

            productService.getProducts(products).then(function (variants) {
                parseProductData(variants, product, true);
            });
        }

        /**
         * @description Method to get all variant product information and show the picker.
         * @param {Object[]} variants variants to parse information
         * @param {Object} product initial product from which the picker was triggered
         * @param {Boolean} excludeColorSwatches optional parameter to determine if all color swatched should be displayed
         * @returns {void}
         */
        function parseProductData(variants, product, excludeColorSwatches) {
            vm.variants = variants;
            vm.currentProduct = product;

            vm.variants.concat(vm.currentProduct).forEach(function(variant) {
                variant.sizes = variant.sizes
                    .map(function(size) {
                        size.id = size.id.replace('B', '');
                        size.value = size.value.replace('B', '');

                        /* Due to the new catalogs, some colors do not have the same sizes available in the catalog -> the size swatches should not be displayed at all */
                        size.display = vm.variants.some((productVariant) => productVariant.colorId === variant.colorId && productVariant.size === size.value);

                        return size;
                    }).
                    sort(function (a, b) {
                        let sizeA = Number(a.id.replace(',', '.')),
                            sizeB = Number(b.id.replace(',', '.'));

                        if (isNaN(sizeA) || isNaN(sizeB)) {
                            let splitSizeA = a.value.split('-');
                            let splitSizeB = b.value.split('-');

                            if (splitSizeA.length === 2 && !isNaN(Number(splitSizeA[0])) && splitSizeB.length === 2 && !isNaN(Number(splitSizeB[0]))) {
                                return Number(splitSizeA[0]) - Number(splitSizeB[0]);
                            }

                            if (SIZE_SORTING_ORDER) {
                                let sizeAIndex = SIZE_SORTING_ORDER.findIndex((size) => a.value === size),
                                    sizeBIndex = SIZE_SORTING_ORDER.findIndex((size) => b.value === size);

                                return sizeAIndex - sizeBIndex;
                            }

                            return 0;

                        }
                        return sizeA > sizeB ? 1 : -1;
                    });

                if (variant.size) {
                    variant.size = variant.size.replace('B', '');
                }
                variant.sizeMap = productService.getSizeMap(variant.sizes, variant);

                if (excludeColorSwatches) {
                    variant.colors = variant.colors.map(function(color) {
                        color.orderable = vm.variants.some((productVariant) => productVariant.colorId === color.id);
                        return color;
                    });
                }
            });

            vm.selectedSize = {
                'id': product.size,
                'available': product.stock > 0
            };
            vm.selectedColor = {
                'id': product.colorId,
                'available': product.stock > 0,
                'name': product.color
            };
            vm.variationDataReady = true;
        }

        /**
         * @description Method to check if product has sizes
         * @returns {Boolean} true if product has sizes
         * @memberOf jwsdwProductDetail.dataService
         */
        function hasSizes() {
            return Object.keys(vm.currentProduct.sizeMap.sizes).some(function(type) {
                return vm.currentProduct.sizeMap.sizes[type].length > 0;
            });
        }

        /**
         * @description Method broadcast to the basket that the product has been updated
         * @returns {void}
         */
        function updateProduct() {
            if (!vm.isBonus) {
                window.jwsdwMediator.publish('updateBasketProduct', {
                    'itemId': vm.basketItemId,
                    'initialProductId': vm.initialProductId,
                    'variantId': vm.currentProduct.id,
                    'quantity': getQuantity(),
                    'product': vm.currentProduct
                });
            } else {
                window.jwsdwMediator.publish('updateBonusProduct', {
                    'itemId': vm.basketItemId,
                    'initialProductId': vm.initialProductId,
                    'variantId': vm.currentProduct.id,
                    'product': vm.currentProduct
                });
            }

        }

        /**
         * @description Method to change product based on color and size IDs
         * @param {String} sizeId size ID
         * @param {String} colorId color ID
         * @returns {void}
         * @private
         */
        function _changeProduct(sizeId, colorId) {
            let productToSelect = vm.variants.find(function(variation) {
                return variation.size === sizeId && variation.colorId === colorId;
            });

            if (productToSelect) {
                vm.currentProduct = productToSelect;
            } else {
                /* Case: The user has selected a color for which the current size variation does not exist
                => we will automatically select the first size we find for the newly selected color */

                productToSelect = vm.variants.find(function(variation) {
                    return variation.colorId === colorId;
                }) || vm.currentProduct;

                vm.currentProduct = productToSelect;
                vm.selectedSize = productToSelect.sizes.find((size) => size.id === productToSelect.size);
            }
        }

        /**
         * @description Method to get the name of a color by the color id and save it to the color names object in the view model.
         * @param {String} colorId the id of the color for which the name should be retrieved
         * @returns {String} color name
         */
        function colorName(colorId) {
            var variant,
                color = '';

            if (vm.colorNames.hasOwnProperty(colorId)) {
                color = vm.colorNames[colorId];
            } else {
                variant = getFirstVariantByColorId(colorId);
                if (variant) {
                    color = variant.color;
                    vm.colorNames[colorId] = color;
                }
            }

            return color;
        }

        /**
         * @description Method to get the first variant for a color id as representative for the group.
         * @param {String} colorId the id of the color for which the first variant should be retrieved
         * @returns {Object} variant product object
         */
        function getFirstVariantByColorId(colorId) {
            return vm.variants.find(function (variant) {
                return variant.colorId === colorId;
            });
        }

        /**
         * @description Method to get a product variant by its id.
         * @param {String} id id of the product variant that should be retrieved
         * @returns {Object} variant product object
         */
        function getVariantById(id) {
            return vm.variants.find(function (variant) {
                return variant.id === id;
            });
        }

        /**
         * @description Method to get the quantity of the variant currently in the basket and check if the currently selected variant
         * is available in the same quantity. If not the maximum stock of the variant is returned.
         * @returns {Number} basketQuantity or stock
         */
        function getQuantity() {
            var basketQuantity = vm.dataService.productQuantity(vm.basketItemId),
                stock = vm.currentProduct.stock;
            return basketQuantity > stock ? stock : basketQuantity;
        }
    }
}(angular));
