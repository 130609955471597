'use strict';

(function (angular) {
    ocapiProductService.$inject = ['$window', '$http', '$q', '$log'];
    angular
        .module('jwsdw.ocapi')
        .factory('ocapiProductService', ocapiProductService);

    /**
     * @class jwsdwOCAPI.ocapiProductService
     * @description OCAPI product service that provides access to product ocapi resource.
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $log log service that provides logging in angluar
     * @returns {Object} service object that returns public methods
     */
    function ocapiProductService($window, $http, $q, $log) {
        var ocapiVersion = 'v17_2',
            service;

        service = {
            'getProducts': getProducts,
            'getAvailability': getAvailability,
            'getExpandedInformation': getExpandedInformation
        };

        return service;

        /**
         * @description Method to request product data from the ocapi and parse received data.
         * @param {String} productIds ids of the products that are requested from the ocapi
         * @param {String[]} customAttributes expand response by custom attributes
         * @returns {Object} Promise which delivers parsed product data for the given product ids
         */
        function getProducts(productIds, customAttributes) {
            var defer = $q.defer(),
                params = {};

            // init request parameters
            params.client_id = $window.jwsdwSettings.clientId || '';
            params.expand = 'availability,links,images,prices,variations';

            if (customAttributes) {
                customAttributes.forEach(function(attribute) {
                    params['c_' + attribute] = true;
                });
            }

            if ($window.jwsdwSettings.locale !== '') {
                params.locale = $window.jwsdwSettings.locale;
            }
            if ($window.jwsdwSettings.currency !== '') {
                params.currency = $window.jwsdwSettings.currency;
            }

            $log.debug('jwsdw.ocapi: Get product data for products with ids', productIds);

            $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/products/(' + productIds + ')',
                'params': params
            }).then(success, error);

            return defer.promise;

            /**
             * Function on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                $log.debug('jwsdw.ocapi: Product data request successful:', response);
                defer.resolve(_parseData(response.data, customAttributes));
            }

            /**
             * Function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                $log.debug('jwsdw.ocapi: Product data request not successful:', response);
                defer.reject(response);
            }
        }

        /**
         * @description Method to request availability data for a product.
         * @param {String} productId id of the product for which availability data is requested
         * @returns {Object} promise with the availability data
         */
        function getAvailability(productId) {
            var params = {};
            $log.debug('jwsdw.ocapi: Get product availability for product with id', productId);
            params.client_id = $window.jwsdwSettings.clientId || '';
            if ($window.jwsdwSettings.locale !== '') {
                params.locale = $window.jwsdwSettings.locale;
            }

            return $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/products/' + productId + '/availability',
                'params': params
            });
        }

        /**
         * @description Method to request expanded data for a product.
         * @param {String} productIds id of the product for which availability data is requested
         * @param {String[]} expansions array of expansion parameters
         * @returns {Object} promise with the availability data
         */
        function getExpandedInformation(productIds, expansions) {
            var params = {
                'expand': expansions.join(',')
            };
            $log.debug('jwsdw.ocapi: Get product availability for products with ids', productIds);
            params.client_id = $window.jwsdwSettings.clientId || '';
            if ($window.jwsdwSettings.locale !== '') {
                params.locale = $window.jwsdwSettings.locale;
            }

            return $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/products/(' + productIds + ')',
                'params': params
            });
        }

        /**
         * @description Method to parse raw product data for all products received from the ocapi.
         * @param {Object} data raw product data for all products
         * @param {Object[]} data.data array of raw product data
         * @param {String[]} customAttributes expand response by custom attributes
         * @returns {Object[]} parsed data
         * @private
         */
        function _parseData(data, customAttributes) {
            var parsedData = [],
                product;

            if (data.count > 0) {
                parsedData = data.data.map(function(productData) {
                    product = {};

                    // add properties which can be directly added
                    product.id = productData.id;
                    product.variants = _parseVariants(productData.variants);
                    product.category = productData.c_category;
                    product.color = productData.c_refinementColor;
                    product.jackWolfskinColors = productData.c_jackWolfskinColors;
                    product.colors = productData.variation_attributes ? _parseColors(productData.variation_attributes[0].values, product) : null;
                    product.colorId = productData.c_color;
                    product.size = productData.c_size;
                    product.sizes = productData.variation_attributes ? _parseSizes(productData.variation_attributes[1].values) : null;
                    product.sizeName = _parseSizeName(product.size, product.sizes);
                    product.currency = productData.currency;
                    product.currencySymbol = getCurrencySymbol(product.currency);
                    product.images = productData.image_groups ? productData.image_groups[0].images : null;
                    product.isSale = productData.c_isSalePrice || false;
                    product.link = productData.c_link || null;
                    product.logos = productData.c_StyleLogo ? angular.fromJson(productData.c_StyleLogo).items : null;
                    product.longDescription = productData.long_description;
                    product.name = productData.name;
                    product.displayedName = productData.c_displayedName;
                    product.price = productData.price;
                    product.priceWithoutVAT = productData.c_priceWithoutVAT;
                    product.prices = productData.c_prices;
                    product.bestPrice = productData.c_bestPrice;
                    product.primaryImage = 0;
                    product.refinementInfo = productData.c_Info ? angular.fromJson(productData.c_Info).items : null;
                    product.refinements = _getRefinements(productData);
                    product.shortDescription = productData.short_description;
                    product.orderable = productData.inventory.orderable;
                    product.stock = productData.inventory.ats;
                    product.productGender = productData.c_Gender;
                    product.normalizedGender = productData.c_normalizedGender;
                    product.sizeType = productData.c_sizeType;
                    product.customType = productData.c_type;
                    product.division = productData.c_division;
                    product.warmth = productData.c_Warmth;
                    product.zip = productData.c_Systemreissverschluss ? angular.fromJson(productData.c_Systemreissverschluss).items : null;

                    if (customAttributes) {
                        customAttributes.forEach(function (attribute) {
                            product[attribute] = productData['c_' + attribute];
                        });
                    }

                    return product;
                });
            }
            return parsedData;
        }

        /**
         * @description Method to parse raw variants data of a product.
         * @param {Object[]} variants all product variants of a product
         * @param {Object} variants[].variation_values varation values
         * @param {String} variants[].variation_values.color color
         * @param {String} variants[].variation_values.size size
         * @param {Number[]} variants[].price product price
         * @param {String} variants[].product_id product Id
         * @param {Boolean} variants[].orderable true if orderable
         * @returns {Object[]} parsed variants
         * @private
         */
        function _parseVariants(variants) {
            return variants
                .filter(function(variant) {
                    var variationValues = variant.variation_values;
                    return variant.price && variationValues;
                })
                .map(function (variant) {
                    var variationValues = variant.variation_values;
                    return {
                        'id': variant.product_id,
                        'orderable': variant.orderable,
                        'color': variationValues ? variationValues.color : null,
                        'size': variationValues ? variationValues.size : null,
                        'price': variant.price
                    };
                });
        }

        /**
         * @description Method to parse sizes
         * @param {Object[]} sizes all sizes for a product with value and name
         * @param {String} sizes[].value size value
         * @returns {Object[]} sizes updated sizes with id
         * @private
         */
        function _parseSizes(sizes) {
            return sizes.map(function(size) {
                size.id = size.value;
                size.display = true;
                return size;
            });
        }

        /**
         * @description Method to parse size name for a given size id.
         * @param {String} size value for which the name should be retrieved
         * @param {Object[]} sizes all sizes for a product with value and name
         * @returns {String} sizes name
         * @private
         */
        function _parseSizeName(size, sizes) {
            var sizeData = sizes.find(function (sizeRefinement) {
                return size === sizeRefinement.value;
            });

            return sizeData && sizeData.hasOwnProperty('name') ? sizeData.name : size;
        }

        /**
         * @description Method to parse colors
         * @param {Object[]} colors all colors for a product with value and name
         * @param {Object} product the product
         * @returns {Object[]} colors updated colors with id
         * @private
         */
        function _parseColors(colors, product) {
            return colors.map(function(color) {
                var variant = _getFirstVariantByColor(product.variants, color.value);
                var colorHasOrderableVariants = product.variants
                    .filter(function(v) {
                        return v.color === color.value;
                    })
                    .some(function (c) {
                        return c.orderable;
                    });

                color.id = color.value;
                color.rgb = product.jackWolfskinColors[color.value];
                color.isBright = window.jwsdwUtil.colorUtils.isBrightColor(color.rgb);

                color.orderable = color.orderable || colorHasOrderableVariants;
                // used for creating product image
                color.productId = variant ? variant.id : null;
                return color;
            });
        }

        /**
         * @description Method to get first variant by color id
         * @param {Object[]} variants product variants
         * @param {String} variants[].color product color
         * @param {String} colorId color ID
         * @returns {Object} first variant
         * @private
         */
        function _getFirstVariantByColor(variants, colorId) {
            return variants.find(function(variant) {
                return variant.color === colorId;
            });
        }

        /**
         * @description Method to get the refinementInfo of a product
         * @param {Object} productData product object
         * @param {String} productData.c_refinementFunktion refinementFunktion
         * @param {String} productData.c_refinementAktivitaet refinementAktivitaet
         * @param {String} productData.c_refinementAusstattungsdetails refinementAusstattungsdetails
         * @returns {Object} refinements
         */
        function _getRefinements(productData) {
            return [
                {
                    'id': 'refinementFunktion',
                    'values': productData.c_refinementFunktion
                },
                {
                    'id': 'refinementAktivitaet',
                    'values': productData.c_refinementAktivitaet
                },
                {
                    'id': 'refinementAusstattungsdetails',
                    'values': productData.c_refinementAusstattungsdetails
                }
            ];
        }

        /**
         * @description Method to get the currency symbol fo a currency code.
         * @param {String} currency code for which the symbol is retrieved
         * @returns {String} currency symbol matching the currency code
         */
        function getCurrencySymbol(currency) {
            var currencySymbol;

            if (currency === 'EUR') {
                currencySymbol = '€';
            } else if (currency === 'GBP') {
                currencySymbol = '£';
            } else if (currency === 'PLN') {
                currencySymbol = 'zł';
            } else if (currency === 'RUB') {
                currencySymbol = 'руб';
            } else {
                currencySymbol = currency;
            }

            return currencySymbol;
        }
    }
}(angular));
