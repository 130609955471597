'use strict';

(function (angular) {
    angular
        .module('jwsdw.util')
        .filter('padNumber', digits);

    /**
     * @class jwsdwUtil.padNumber
     * @description Filter padding numbers with leading zeros
     * @returns {Function} filter to pad numbers
     */
    function digits() {
        return window.jwsdwUtil.padNumber;
    }
}(angular));