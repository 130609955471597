'use strict';

(function (angular) {
    contentService.$inject = ['$q', '$sce', 'ocapiContentService'];
    angular
        .module('jwsdw.services')
        .factory('contentService', contentService);

    /**
     * @class jwsdwServices.contentService
     * @description Service to provide access functions to the customer information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $sce angular santize service
     * @param {Object} ocapiContentService service to provide access to the ocapi customer resource
     * @returns {Object} service object that returns public methods
     */
    function contentService($q, $sce, ocapiContentService) {
        var service;

        service = {
            'getContent': getContent,
            'getContentAssets': getContentAssets,
            'cancelGetContent': cancelGetContent
        };

        return service;

        /**
         * @description method to get content by id
         * @param {String} cid content asset id
         * @returns {Object} promise
         */
        function getContent(cid) {
            var defer = $q.defer();
            ocapiContentService.getContent(cid).then(function(contentData) {
                defer.resolve(_parseData(contentData));
            });

            return defer.promise;
        }

        /**
         * @description method to get multiple content assets
         * @param {String[]} cids array of content asset ids
         * @returns {Object} promise
         */
        function getContentAssets(cids) {
            var defer = $q.defer();
            ocapiContentService.getContentAssets(cids).then(function(contentData) {
                contentData.data = contentData.data.map(function(data) {
                    return _parseData(data);
                });
                defer.resolve(contentData.data);
            });

            return defer.promise;
        }

        /**
         * @description method to cancel get search suggestions via XHR
         * @returns {void}
         */
        function cancelGetContent() {
            ocapiContentService.cancelGetContent();
        }

        /**
         * @description method to parse ocapi response data
         * @param {Object} data ocapi response
         * @param {String} data.id content id
         * @param {String} data.c_url url
         * @param {String} data.name name
         * @param {String} data.c_body body
         * @param {String} data.c_Image image
         * @param {String} data.c_Excerpt excerpt
         * @returns {Object} parsedData
         */
        function _parseData(data) {
            return {
                'id': data.id,
                'url': data.c_url,
                'headline': data.name,
                'content': data.c_body,
                'image': data.c_Image,
                'excerpt': data.c_Excerpt
            };
        }

    }
}(angular));