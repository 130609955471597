'use strict';

(function (angular) {
    angular.module('jwsdw.framework', [
        'ngSanitize',
        'ngAnimate',
        'jwsdw.services',
        'jwsdw.ocapi',
        'jwsdw.product',
        'jwsdw.tracking',
        'jwsdw.util',
        'sticky',
        'jwsp.picture',
        'jwsp.picker',
        'jwsp.util'
    ]);
}(angular));