'use strict';

(function (angular) {
    angular
        .module('jwsdw.util')
        .filter('jwsdwFormatNumber', jwsdwFormatNumber);

    /**
     * @class jwsdwUtil.jwsdwFormatNumber
     * @description Filter number format
     * @returns {Function} filter to get formatted number
     */
    function jwsdwFormatNumber() {
        return window.jwsdwUtil.applyNumberFormat;
    }
}(angular));