'use strict';

(function (angular) {
    LocationService.$inject = ['$log', '$q', '$window', '$http', 'REGION', 'REQUESTTOKEN', 'COUNTRYCODE'];
    angular
        .module('jwsdw.services')
        .factory('locationService', LocationService);

    /**
     * @class jwsdwServices.LocationService
     * @description Handles the request for getting store information
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $window browser window object
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {String} REGION current region of the user (provided through jwsdwSettings)
     * @param {String} REQUESTTOKEN current build number (provided through jwsdwSettings)
     * @param {String} COUNTRYCODE current country code (provided through jwsdwSettings)
     * @returns {Object} service object that returns public methods
     */
    function LocationService($log, $q, $window, $http, REGION, REQUESTTOKEN, COUNTRYCODE) {
        var service = {
                'getGeolocation': getGeolocation,
                'geolocateAdress': geolocateAdress,
                'getAddressByCoordinates': getAddressByCoordinates,
                'error': error
            },
            _geoLocationError = null,
            _location = {};

        return service;

        /**
         * @description Method that uuses google maps Geocoder to retrieve coordinates for given adress string
         * @param {String} address address
         * @returns {Promise} Promise that resolves to position object or rejects with error
         * @memberOf jwsdwServices.LocationService
         */
        function getGeolocation(address) {
            var defer = $q.defer(),
                geoCoderArgs = {};

            if (address && address !== '') {
                geoCoderArgs.address = address.trim().replace(/\s+/g, ' ');
                geoCoderArgs.region = REGION;
                geoCoderArgs.countryCode = COUNTRYCODE;

                _getGeolocation(geoCoderArgs).then(getGeolocationSuccess, getGeolocationError);
            } else {
                _location.position = null;
                defer.reject();
            }

            /**
             * function on success
             * @param {Object} response response data
             * @returns {Object} promise
             */
            function getGeolocationSuccess (response) {
                $log.debug('jwsdw.services.LocationService: Google Maps API Geocoding request: ', response.data.success, response);
                if (response.data.success) {
                    _location.position = {
                        'lat': response.data.location.lat,
                        'lng': response.data.location.lng
                    };
                    defer.resolve(_location.position);
                } else {
                    _location.position = null;
                    defer.reject(response.data.success);
                }
            }

            /**
             * function on success
             * @param {Object} errorData error data
             * @returns {Object} promise
             */
            function getGeolocationError (errorData) {
                defer.reject(errorData);
            }

            return defer.promise;
        }

        /**
         * @description Method that uses googles navigator.geolocation.getCurrentPosition() to retrieve current position of user
         * @returns {Promise} Promise that resolves to geolocation object or rejects with error
         * @memberOf jwsdwServices.LocationService
         */
        function geolocateAdress() {
            var defer = $q.defer();

            $window.navigator.geolocation.getCurrentPosition(function(location) {
                _geoLocationError = null;
                _location.position = {
                    'lat': location.coords.latitude,
                    'lng': location.coords.longitude
                };

                defer.resolve(_location.position);
            }, handlePositionError);

            /**
             * @description Method for failed get location call
             * @param {Object} err error object
             * @returns {void}
             */
            function handlePositionError(err) {
                switch (err.code) {
                    case err.PERMISSION_DENIED:
                        // User denied the request for Geolocation
                        _geoLocationError = 'PERMISSION_DENIED';
                        break;
                    case err.POSITION_UNAVAILABLE:
                        // Location information is unavailable
                        _geoLocationError = 'POSITION_UNAVAILABLE';
                        break;
                    case err.TIMEOUT:
                        // The request to get user location timed out
                        _geoLocationError = 'TIMEOUT';
                        break;
                    case err.UNKNOWN_ERROR:
                        // An unknown error occurred
                        _geoLocationError = 'UNKNOWN_ERROR';
                        break;
                }

                defer.reject(_geoLocationError);
            }
            return defer.promise;
        }

        /**
         * @description Method that uses google maps Geocoder to retrieve the adress by coordinates
         * @param {Number} lat Latitude of current position
         * @param {Number} lng Longitude of current position
         * @returns {Promise} Promise that resolves to address
         * @memberOf jwsdwServices.LocationService
         */
        function getAddressByCoordinates(lat, lng) {
            var defer = $q.defer();

            _getGeolocation({'latlng': lat + ',' + lng}).then(getGeolocationSuccess, getGeolocationError);

            /**
             * function on success
             * @param {Object} response response data
             * @returns {Object} promise
             */
            function getGeolocationSuccess (response) {
                if (response.data.success && response.data.address) {
                    _location.address = response.data.address;
                    defer.resolve(_location.address);
                } else {
                    defer.reject();
                }
            }

            /**
             * function on success
             * @param {Object} errorData error data
             * @returns {Object} promise
             */
            function getGeolocationError (errorData) {
                defer.reject(errorData);
            }
            return defer.promise;
        }

        /**
         * @description Method that returns error code in case of an error
         * @returns {String} error code
         * @memberOf jwsdwServices.LocationService
         */
        function error() {
            return _geoLocationError;
        }

        /**
         * @private
         * @description Method to send a request to maps service
         * @param {Object} geoCoderArgs geo corder arguments to pass
         * @returns {Promise} promise
         * @memberOf jwsdwServices.LocationService
         */
        function _getGeolocation(geoCoderArgs) {
            geoCoderArgs.requestToken = REQUESTTOKEN;

            return $http({
                'method': 'GET',
                'url': $window.app.urls.geoCoder,
                'params': geoCoderArgs
            });
        }
    }
}(angular));
