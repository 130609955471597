'use strict';

(function (angular) {
    angular
        .module('jwsdw.ocapi')
        .factory('errorService', errorService);

    /**
     * @class jwsdwOCAPI.errorService
     * @description OCAPI error service that provides error handling for failed ocapi calls.
     * @returns {Object} service object providing access to the error functions
     */
    function errorService() {
        var service,
            observerCallbacks = [];

        service = {
            'throwError': throwError,
            'register': register
        };

        return service;

        /**
         * Method that handles further error handling inside the service.
         * @param {Object} data error data
         * @returns {void}
         */
        function throwError(data) {
            _notifyObservers(data);
        }

        /**
         * @description Method to internally register callback functions of the observers.
         * @param {Function} callback callback function for the observer
         * @returns {void}
         */
        function register(callback) {
            observerCallbacks.push(callback);
        }

        /**
         * @description Method to pass error data to all callback functions.
         * @param {Object} data error data
         * @returns {void}
         * @private
         */
        function _notifyObservers(data) {
            angular.forEach(observerCallbacks, function(callback) {
                callback(data);
            });
        }
    }
}(angular));