'use strict';

(function (angular) {
    angular.module('jwsdw.picker', [
        'ngSanitize',
        'jwsdw.framework',
        'jwsdw.ocapi',
        'jwsdw.product',
        'jwsdw.services',
        'jwsdw.store',
        'jwsdw.tracking',
        'jwsp.picker',
        'svg-embed'
    ]);
}(angular));