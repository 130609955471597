'use strict';

(function (angular) {

    StoreCard.$inject = ['storeService'];
    angular
        .module('jwsdw.store')
        .directive('jwsdwStoreCard', jwsdwStoreCard)
        .directive('jwsdwMapStoreCard', jwsdwMapStoreCard);

    /**
     * @class jwsdwDtore.jwsdwStoreCard
     * @description Store Card
     * @returns {Object} directive
     */
    function jwsdwStoreCard() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'storeCard.html',
            'replace': true,
            'scope': {
                'store': '=',
                'showAvailability': '=',
                'storeServices': '=',
                'isMyStore': '='
            },
            'controller': StoreCard,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /**
     * @class jwsdwDtore.jwsdwStoreCard
     * @description Store Card
     * @returns {Object} directive
     */
    function jwsdwMapStoreCard() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'mapStoreCard.html',
            'replace': true,
            'scope': {
                'store': '=',
                'showAvailability': '=',
                'storeServices': '=',
                'isMyStore': '='
            },
            'controller': StoreCard,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwStoreFinder.jwsdwStoreCard.StoreCard
     * @description Controller for size selection input
     * @constructor
     * @param {Object} storeService service for finding stores
     */
    function StoreCard(storeService) {
        var vm = this;

        vm.linkToDirections = linkToDirections;
        vm.containsService = containsService;
        vm.showServices = false;
        vm.close = close;

        /**
         * @description Method to run the initialization of the product detail module
         * @returns {void}
         */
        this.$onInit = function () {
            try {
                if (typeof vm.store.storeHours === 'string') {
                    vm.store.storeHours = JSON.parse(vm.store.storeHours);
                }
                vm.store.openFor = storeService.storeOpenFor(vm.store.storeHours);
                vm.store.openNow = storeService.isStoreOpen(vm.store.storeHours);
            } catch (e) {
                // error
            }
            vm.store.hasStoreHours = vm.store.storeHours && Object.keys(vm.store.storeHours).length > 0;
        };

        /**
         * @description Method to get link to google maps with directions
         * @param {Object} store the store object
         * @returns {String} url to google maps
         */
        function linkToDirections(store) {
            var destination = [
                store.name,
                store.address1 || '',
                store.address2 || '',
                store.postalCode || '',
                store.city || ''
            ].join('+').replace(/\s/, '+');

            return 'https://www.google.com/maps/dir/?api=1&destination=' + destination;
        }

        /**
         * @description Method to find out if specific services is offered by store
         * @param {Object} service service object
         * @returns {Boolean} true if store offers service
         */
        function containsService(service) {
            return vm.store.services.some(function(srvc) {
                return srvc.id === service.id;
            });
        }

        /**
         * @description Method to trigger close
         * @returns {void}
         */
        function close() {
            window.jwsdwMediator.publish('closeStoreCard');
        }
    }
}(angular));