'use strict';

(function (angular) {
    SizeGuideController.$inject = ['$window', '$scope', 'SIZEGUIDE'];
    angular
        .module('jwsdw.product')
        .directive('jwsdwSizeGuide', jwsdwSizeGuide);

    /**
     * @class jwsdwProduct.jwsdwSizeGuide
     * @description Size Guide
     * @returns {Object} directive object
     */
    function jwsdwSizeGuide() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'sizeGuide.html',
            'replace': true,
            'scope': {
                'sizeGuide': '=jwsdwSizeGuide',
                'type': '=jwsdwType',
                'gender': '=jwsdwGender',
                'availableSizes': '=jwsdwAvailableSizes'
            },
            'controller': SizeGuideController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwSizeGuide.SizeGuideController
     * @description Controller Size Guide
     * @param {Object} $window current window wrapped as jquery element
     * @param {Object} $scope current scope
     * @param {Object} SIZEGUIDE size guide size map with all sizes and measurements
     * @returns {void}
     */
    function SizeGuideController($window, $scope, SIZEGUIDE) {
        var vm = this,
            _sizeTypes = [
                'regular',
                'short',
                'tall',
                'oversize',
                'fingerGloves',
                'mittens'
            ];

        vm.sizeTypesAvailable = _getSizeTypesAsUnavailable(_sizeTypes);

        vm.unit = 'cm';
        vm.sizes = {};
        vm.selectedSizes = [];
        vm.selectSize = selectSize;
        vm.toggleUnit = toggleUnit;

        this.$onInit = function () {
            vm.type = vm.type || 'tops';
            vm.gender = vm.gender || 'women';
            vm.showEUSizes = SIZEGUIDE.showEUSizes;
            vm.showUKSizes = SIZEGUIDE.showUKSizes;
            vm.showUSSizes = SIZEGUIDE.showUSSizes;
            vm.sizesHaveLetters = true;

            $scope.$watchGroup(['vm.type', 'vm.gender', 'vm.availableSizes'], function() {
                if (vm.availableSizes) {
                    vm.sizesHaveLetters = vm.availableSizes.some(function(size) {
                        return /[a-zA-Z]/.test(size);
                    });
                }
                vm.sizeGuideSizeMap = SIZEGUIDE.map[vm.type][vm.gender];

                if (!vm.sizeGuideSizeMap) {
                    // get gender configured for size type
                    vm.sizeGuideSizeMap = SIZEGUIDE.map[vm.type][Object.keys(SIZEGUIDE.map[vm.type])[0]];
                }
                vm.title = SIZEGUIDE.titleMap[vm.gender][vm.type];
                vm.selectedSizes = [];
                vm.sizes = {};

                if (!vm.sizeGuideSizeMap) {
                    return;
                }

                vm.sizeGuideSizeMap.sizes = _sizeTypes.reduce(function(obj, type) {
                    obj[type] = _getSize(type);
                    return obj;
                }, {});

                vm.sizeGuideSizeMap.sizeNumbers = _sizeTypes.reduce(function(obj, type) {
                    obj[type] = _getSizeNumbers(type);
                    return obj;
                }, {});

                vm.sizeTypesAvailable = _getSizeTypesAsUnavailable(_sizeTypes);

                if (!vm.availableSizes || vm.availableSizes[0] === 'ONE SIZE') {
                    vm.sizeTypesAvailable.regular = true;
                    return;
                }

                _sizeTypes.forEach(function(sizeType) {
                    vm.sizeGuideSizeMap.sizes[sizeType] = vm.sizeGuideSizeMap.sizes[sizeType].filter(function(size) {
                        return vm.sizesHaveLetters ? /[a-zA-Z]/.test(size) : /[0-9]/.test(size);
                    });
                });

                vm.sizeGuideSizeMap.sizes.regular.forEach(function(size) {
                    if (vm.availableSizes.indexOf(String(size)) !== -1) {
                        vm.sizeTypesAvailable.regular = true;
                    }
                });

                vm.sizeGuideSizeMap.sizes.oversize.forEach(function(size) {
                    if (vm.availableSizes.indexOf(String(size)) !== -1) {
                        vm.sizeTypesAvailable.oversize = true;
                    }
                });
                if (vm.type === 'trousers' || vm.type === 'skirts') {
                    vm.sizeGuideSizeMap.sizes.short.forEach(function(size) {
                        if (vm.availableSizes.indexOf(String(size)) !== -1) {
                            vm.sizeTypesAvailable.short = true;
                        }
                    });
                    vm.sizeGuideSizeMap.sizes.tall.forEach(function(size) {
                        if (vm.availableSizes.indexOf(String(size)) !== -1) {
                            vm.sizeTypesAvailable.tall = true;
                        }
                    });
                }

                _sizeTypes.forEach(function(type) {
                    vm.sizeGuideSizeMap.sizeNumbers[type].forEach(function(size) {
                        if (vm.availableSizes.indexOf(String(size)) !== -1) {
                            vm.sizeTypesAvailable[type] = true;
                        }
                    });
                });

                if (_noTypesAvailable()) {
                    vm.sizeTypesAvailable.regular = true;
                }
                if (vm.sizeTypesAvailable.oversize) {
                    vm.sizeTypesAvailable.regular = false;
                }
            });

            $window.jwsdwMediator.subscribe('closePicker', function(type) {
                if (type === 'sizeGuidePicker') {
                    vm.selectedSizes = [];
                }
            });
        };

        /**
         * @description Method to get sizes
         * @param {String} sizeType size type (regular, short, tall, fingerGloves, mittens, default)
         * @returns {String[]} sizes
         */
        function _getSize(sizeType) {
            var sizes = [];

            if (!vm.sizeGuideSizeMap.hasOwnProperty(sizeType)) {
                return sizes;
            }

            sizes = Object.keys(vm.sizeGuideSizeMap[sizeType] || {});
            return sizes;
        }

        /**
         * @description Method to get size number
         * @param {String} sizeType size type (regular, short, tall, fingerGloves, mittens, default)
         * @returns {Number[]} sizeNumbers array of size numbers
         */
        function _getSizeNumbers(sizeType) {
            var sizeNumbers = [];

            if (!vm.sizeGuideSizeMap.hasOwnProperty(sizeType)) {
                return sizeNumbers;
            }
            Object.keys(vm.sizeGuideSizeMap[sizeType] || {}).forEach(function(size) {
                var sizeNumber = vm.sizeGuideSizeMap[sizeType][size].number || size,
                    i;

                if (!sizeNumber) {
                    return;
                }
                if (typeof sizeNumber === 'object') {
                    for (i = sizeNumber.from; i < sizeNumber.to; i++) {
                        sizeNumbers.push(i);
                    }
                } else {
                    sizeNumbers.push(sizeNumber);
                }
            });
            return sizeNumbers;
        }

        /**
         * @description Method to get all size types as unavailable
         * @param {String[]} sizeTypes size types
         * @return {Object} sizetype map where values are all false
         */
        function _getSizeTypesAsUnavailable(sizeTypes) {
            return sizeTypes.reduce(function(obj, type) {
                obj[type] = false;
                return obj;
            }, {});
        }

        /**
         * @description Method to check if no size types are available
         * @returns {Boolean} true if no size type is available
         */
        function _noTypesAvailable() {
            return _sizeTypes.every(function(type) {
                return !vm.sizeTypesAvailable[type];
            });
        }

        /**
         * @description Method to select size for display in size table
         * @param {String} sizeType size type (regular, short, tall, fingerGloves, mittens, default)
         * @param {String} size size (S, M, L, ...)
         * @returns {Object[]} selectedSizes
         */
        function selectSize(sizeType, size) {
            var alreadySelected = vm.selectedSizes.filter(function(sizeObj) {
                    return sizeObj.type === sizeType && sizeObj.size === size;
                }).length > 0,
                deselectSize;

            if (alreadySelected) {
                // if size already selected, deselect it
                vm.selectedSizes = vm.selectedSizes.filter(function(sizeObj) {
                    return sizeObj.type !== sizeType || sizeObj.size !== size;
                });
                return vm.selectedSizes;
            }

            vm.selectedSizes.push({
                'type': sizeType,
                'size': size
            });

            if (vm.selectedSizes.length > 2) {
                deselectSize = vm.selectedSizes.shift();
                vm.sizes[deselectSize.type + '-' + deselectSize.size] = false;
            }

            return vm.selectedSizes;
        }

        /**
         * @description Method to toggle measurement unit
         * @returns {String} unit
         */
        function toggleUnit() {
            vm.unit = vm.unit === 'cm' ? 'in' : 'cm';
            return vm.unit;
        }
    }
}(angular));