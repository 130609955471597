(function (angular) {
    // initialize picker angular app
    // bootstrap app manually as only the first app on a page can be initialized by data-ng-app (which is the framework/header)
    angular.bootstrap(document.getElementById('jwsdw-picker-module'), ['jwsdw.picker']);
}(angular));

(function (angular) {
    // initialize picker angular app
    // bootstrap app manually as only the first app on a page can be initialized by data-ng-app (which is the framework/header)
    angular.bootstrap(document.getElementById('jwsdw-pageCover-module'), ['jwsp.pageCover']);
}(angular));

(function (angular) {
    // initialize breadcrumbs angular app
    // bootstrap app manually as only the first app on a page can be initialized by data-ng-app (which is the framework/header)
    angular.bootstrap(document.getElementById('jwsdw-breadcrumbs-module'), ['jwsdw.breadcrumbs']);
}(angular));

(function (angular) {
    // initialize sticky add to cart button angular app
    // bootstrap app manually as only the first app on a page can be initialized by data-ng-app (which is the framework/header)
    angular.bootstrap(document.getElementById('jwsdw-stickyAddToCart'), ['jwsdw.stickyAddToCart']);
}(angular));
