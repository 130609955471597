'use strict';

(function (angular) {
    ReservationInStoreFormController.$inject = ['$scope', '$rootScope', 'reserveInStoreService'];
    angular
        .module('jwsdw.store')
        .directive('jwsdwReservationInStoreForm', jwsdwReservationInStoreForm);

    /**
     * @class jwsdwStore.jwsdwReservationInStoreForm
     * @description This is the form for reserving products in JW stores (contains inputs for e-Mail and phone)
     * @returns {Object} directive
     */
    function jwsdwReservationInStoreForm() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'reserverationInStoreForm.html',
            'replace': true,
            'scope': {
                'store': '=jwsdwReservationInStoreForm',
                'productId': '='
            },
            'controller': ReservationInStoreFormController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwStore.jwsdwReservationInStoreForm.ReservationInStoreFormController
     * @description Controller for reservation form provides data for submit service
     * @param {Object} $scope current scope
     * @param {Object} $rootScope current root scope
     * @param {Object} reserveInStoreService provides the reserve in store functionality
     * @constructor
     */
    function ReservationInStoreFormController($scope, $rootScope, reserveInStoreService) {
        var vm = this;

        vm.onSubmitReservationInStoreForm = onSubmitReservationInStoreForm;

        /**
         * @description Method to run the initialization of the reserve in store form controller
         * @returns {void}
         * @memberOf jwsdwPicker.ReservationInStoreFormController
         */
        this.$onInit = function () {
            // Reflects current frontend state
            // Possible values:
            // - 'normal'
            // - 'reservationSuccessful'
            // - 'reservationFailed'
            vm.state = 'normal';

            vm.isLoading = false;
            vm.reservationFormData = {};
            $scope.$watch('vm.store', function() {
                vm.state = 'normal';
            });
        };

        /**
         * @description Method to submit the provided data
         * @param {Object} form Form object which contains validation data like form.$valid True, if current user input is valid
         * @returns {void}
         * @memberOf jwsdwPicker.jwsdwReservationInStoreForm.ReservationInStoreFormController
         */
        function onSubmitReservationInStoreForm(form) {
            var $salutation = $('#jwsdw-reserveInStore-salutation'),
                $firstName = $('#jwsdw-reserveInStore-firstName'),
                $lastName = $('#jwsdw-reserveInStore-lastName'),
                $email = $('#jwsdw-reserveInStore-email'),
                $phone = $('#jwsdw-reserveInStore-phone');

            if (form.$valid) {
                vm.isLoading = true;
                reserveInStoreService.reserve(
                    vm.productId,
                    $salutation.val(),
                    $firstName.val(),
                    $lastName.val(),
                    $email.val(),
                    $phone.val(),
                    vm.store.email,
                    vm.store.reservationemail || vm.store.email,
                    vm.store.city,
                    vm.store.storeType,
                    vm.store.id
                ).then(success, error);
            }

            /**
             * @description Method on success for the store reservation mail
             * @returns {void}
             */
            function success() {
                vm.isLoading = false;
                vm.state = 'reservationSuccessful';
            }

            /**
             * @description Method on error for the store reservation mail
             * @returns {void}
             */
            function error() {
                vm.isLoading = false;
                vm.state = 'reservationFailed';
            }
        }
    }
}(angular));