'use strict';

(function (angular) {

    httpInterceptor.$inject = ['$q', 'errorService'];
    angular
        .module('jwsdw.ocapi')
        .factory('httpInterceptor', httpInterceptor);

    /**
     * @class jwsdwOCAPI.httpInterceptor
     * @description http interceptor service that handles errors during ajax requests
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} errorService service that handles passing of errors to observers
     * @returns {Object} service object that returns public methods
     */
    function httpInterceptor($q, errorService) {
        var service = {
            'responseError': responseError
        };

        return service;

        /**
         * Method that handles further error handling inside the service.
         * @param {Object} response response object
         * @returns {Object} promise
         */
        function responseError(response) {
            if (!response.config.handleByCaller && response.status !== 400) {
                window.jwsdwApi.popup.showHttpError(response.status, response.status === 401);
            }
            errorService.throwError(response);
            return $q.reject(response);
        }
    }
}(angular));
