'use strict';

(function (angular) {
    cookieService.$inject = ['$q', '$http', '$window', '$log', 'BASE_URL'];
    angular
        .module('jwsdw.services')
        .factory('cookieService', cookieService);

    /**
     * @class jwsdwServices.cookieService
     * @description Service to provide access functions to the customer information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @param {String} BASE_URL base url for service requests
     * @returns {Object} service object that returns public methods
     */
    function cookieService($q, $http, $window, $log, BASE_URL) {
        var service;

        service = {
            'updateCookieSettings': updateCookieSettings
        };

        return service;

        /**
         * @description Method to get the basket.
         * @param {Boolean} preferences should preferences cookies be active
         * @param {Boolean} statistics should statistics cookies be active
         * @param {Boolean} marketing should marketing cookies be active
         * @returns {Promise} promise
         */
        function updateCookieSettings(preferences, statistics, marketing) {
            var defer = $q.defer(),
                gtmLoadedInterval,
                params = {
                    'dntPreferences': preferences,
                    'dntStatistics': statistics,
                    'dntMarketing': marketing
                };

            params[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Session-SetCookiePreferences',
                'params': params
            }).then(success, error);

            /**
             * @description Method on success callback
             * @param {Object} response response
             * @returns {void}
             */
            function success(response) {
                if (response.data.success) {
                    $log.debug('jwsdw.picker.cookieSettings: Cookie Settings Update request successful:', response);
                    window.dynamic_tm_data.deactivatePreferences = !!preferences;
                    window.dynamic_tm_data.deactivateStatistics = !!statistics;
                    window.dynamic_tm_data.deactivateMarketing = !!marketing;

                    gtmLoadedInterval = setInterval(function () {
                        if (isGTMLoaded()) {
                            window.dataLayer.push({
                                'event':'ce.consentUpdate',
                                'consent':{
                                    'statistics': !statistics,
                                    'marketing': !marketing,
                                    'preferences': !preferences
                                }
                            });
                            clearInterval(gtmLoadedInterval);
                            window.scriptOnLoad();
                        }
                    });

                    defer.resolve(response.data);
                } else {
                    defer.reject(response);
                }
            }

            /**
             * @description Method on error callback
             * @param {Object} response error response
             * @returns {void}
             */
            function error(response) {
                $log.debug('jwsdw.picker.cookieSettings: Cookie Settings Update request failed:', response);
                defer.reject(response);
            }

            return defer.promise;
        }

        /**
         * @description Method to determine whether GTM has been loaded
         * @returns {Boolean} true if loaded
         */
        function isGTMLoaded () {
            return window.dataLayer.some(function (event) {
                return event['gtm.start'];
            });
        }
    }
}(angular));