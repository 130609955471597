(function (angular) {
    'use strict';

    angular
        .module('jwsp.util')
        .factory('util', util);

    function util() {
        return {
            getKeyCode: getKeyCode
        };

        function getKeyCode(event) {
            // Use event.which by default and keyCode for old IEs
            // See http://unixpapa.com/js/key.html
            return event.which || event.keyCode;
        }
    }
}(angular));
