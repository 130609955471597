(function () {
    'use strict';

    jwspPicker.$inject = ['$document', '$window', 'util'];
    angular
        .module('jwsp.picker')
        .directive('jwspPicker', jwspPicker);

    function jwspPicker($document, $window, util) {
        var directive = {
            restrict: 'EA',
            scope: {
                active: '=jwspPicker'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            var $body = angular.element('body'),
                el = element;

            scope.$watch('active', function (active) {
                if (active) {
                    openPicker();
                } else {
                    closePicker();
                }
            });

            function openPicker() {
                if (!isPickerInPicker(el)) {
                    $body.addClass('jws-overflowYHidden');
                    $body.addClass('jwsdw-pickerOpen');
                    $body.css('paddingRight', ($window.jwsdwUtil.uiUtils.isScrollbarVisible() ? $window.jwsdwUtil.uiUtils.getScrollbarWidth($body) : 0) + 'px');
                    $document.on('touchmove', '.jws-pageCover', function(event) {
                        event.preventDefault();
                    });
                }
            }

            function closePicker() {
                if (!isPickerInPicker(el)) {
                    $body.removeClass('jws-overflowYHidden');
                    $body.removeClass('jwsdw-pickerOpen');
                    $body.css('paddingRight', 0);
                    $document.off('touchmove', '.jws-pageCover');
                }
            }
        }

        /**
         * Check if element is a picker in picker
         * @param {Object} el picker element
         * @returns {Boolean} Is picker in picker?
         */
        function isPickerInPicker(el) {
            return el.hasClass('jwsdw-pickerLevel2') || el.hasClass('jwsdw-pickerLevel3')
        }
    }
}());
