'use strict';

(function (angular) {
    generalTracking.$inject = ['$window'];
    angular
        .module('jwsdw.tracking')
        .factory('generalTracking', generalTracking);

    /**
     * @class jwsdwTracking.generalTracking
     * @description Initializes the general tracking functionality
     * @param {Object} $window browser window object
     * @constructor
     */
    function generalTracking($window) {
        var service = {
            'send': send
        };

        return service;

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @param {String} data Product information
         * @returns {void}
         */
        function send(data) {
            $window.jwsdwApi.tracking.fireEvent(data.action, {
                'type': data.type
            });
        }
    }
}(angular));