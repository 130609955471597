'use strict';

(function (angular) {
    ocapiPromotionService.$inject = ['$q', '$http', '$window', '$log'];
    angular
        .module('jwsdw.ocapi')
        .factory('ocapiPromotionService', ocapiPromotionService);

    /**
     * @class jwsdwOCAPI.ocapiPromotionService
     * @description OCAPI promotion service that provides access to promotion ocapi resource.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @returns {Object} service object that returns public methods
     */
    function ocapiPromotionService($q, $http, $window, $log) {
        var ocapiVersion = 'v17_3',
            service;

        service = {
            'getPromotion': getPromotion
        };

        return service;

        /**
         * @description Method to Method to get a promotion by promotion id from the ocapi.
         * @param {String} promotionId id of the promotion that is requested.
         * @returns {Object} promise containing promotion data
         */
        function getPromotion(promotionId) {
            var defer = $q.defer();

            _getPromotion(promotionId).then(getPromotionSuccess, getPromotionError);

            /**
             * function on success
             * @param {Object} promotionData promotion data
             * @param {Object} promotionData.data promotion data
             * @returns {Object} promise
             */
            function getPromotionSuccess(promotionData) {
                $log.debug('jwsdw.ocapi: Promotion request successful:', promotionData);
                defer.resolve(promotionData.data);
            }

            /**
             * function on error
             * @param {Object} response response data
             * @returns {Object} promise
             */
            function getPromotionError(response) {
                $log.debug('jwsdw.ocapi: Promotion request failed:', response);
                defer.resolve(response);
            }

            return defer.promise;
        }

        /**
         * OCAPI call to get the promotion with the given id.
         * @param {String} promotionId id of the promotion that is requested.
         * @returns {Object} promise
         * @private
         */
        function _getPromotion(promotionId) {
            $log.debug('jwsdw.ocapi: Get promotion with promotionId', promotionId);
            return $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/promotions/' + promotionId,
                'params': {
                    'client_id': $window.jwsdwSettings.clientId,
                    'locale': $window.jwsdwSettings.locale !== '' ? $window.jwsdwSettings.locale : 'default'
                },
                'handleByCaller': true
            });
        }
    }
}(angular));