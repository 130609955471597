'use strict';

(function (angular) {
    StoreAvailabilityListController.$inject = ['$window', '$scope', 'storeAvailabilityService'];
    angular
        .module('jwsdw.store')
        .directive('jwsdwStoreAvailabilityList', jwsdwStoreAvailabilityList);

    /**
     * @class jwsdwStore.jwsdwStoreAvailabilityList
     * @description The store list for product availability
     * @returns {Object} directive
     */
    function jwsdwStoreAvailabilityList() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'storeAvailabilityList.html',
            'replace': true,
            'scope': {
                'productId': '=jwsdwStoreAvailabilityList',
                'isActive': '=',
                'position': '=',
                'defaultPosition': '=',
                'selectedStore': '='
            },
            'controller': StoreAvailabilityListController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwStore.jwsdwStoreAvailabilityList.StoreAvailabilityListController
     * @description Controller store availability list
     * @param {Object} $window browser window object
     * @param {Object} $scope current scope
     * @param {Object} storeAvailabilityService service to provide access to the store availability functions
     * @constructor
     */
    function StoreAvailabilityListController($window, $scope, storeAvailabilityService) {
        var vm = this,
            currentProductId;

        vm.isLoading = false;
        vm.serviceUnavailable = false;
        vm.stores = null;
        vm.myStore = null;
        vm.moreStoresAvailable = false;
        vm.limit = 20;
        vm.currentPage = 0;

        vm.showMore = showMore;
        vm.selectStore = selectStore;

        /**
         * @description Method to run the initialization of the store availability list controller
         * @returns {void}
         * @memberOf jwsdwStore.StoreAvailabilityListController
         */
        this.$onInit = function () {
            $scope.$watch('vm.isActive', function(isActive) {
                if (isActive && vm.productId !== currentProductId && (vm.position || vm.defaultPosition)) {
                    currentProductId = vm.productId;
                    _update();
                } else {
                    // clear current store list
                    vm.stores = null;
                }
            });

            $scope.$watch('vm.productId', function() {
                if (vm.isActive && (vm.position || vm.defaultPosition)) {
                    currentProductId = vm.productId;
                    _update();
                }
            });

            $scope.$watch('vm.position', function(position) {
                if (vm.isActive && position) {
                    currentProductId = vm.productId;
                    _update();
                } else if (position !== null) {
                    // clear current store list
                    vm.stores = null;
                } else {
                    vm.stores = [];
                    vm.moreStoresAvailable = false;
                }
            });

            $window.jwsdwMediator.subscribe('changeProductId', function(productId) {
                $scope.$apply(function() {
                    if (vm.isActive && productId) {
                        currentProductId = vm.productId = productId;
                    }
                });
            });
        };

        /**
         * @description Method to prepare the response (flatten ATS information and prepares store hours)
         * @param {Object} response Response to prepare
         * @returns {void}
         */
        function _prepareResponse(response) {
            // Flatten ATS information for store result and prepare store hours
            response.stores = response.stores.map(function (store) {
                store.page = vm.currentPage;
                store.ats = store.hasOwnProperty('ats') &&
                    store.ats.hasOwnProperty(vm.productId) && store.ats[vm.productId].hasOwnProperty('quantity') ?
                    store.ats[vm.productId].quantity : 0;

                store.hasProduct = store.ats > 0;
                if (store.storeHours) {
                    try {
                        store.storeOpeningHours = JSON.parse(store.storeHours);
                    } catch (e) {
                        store.storeOpeningHours = store.storeHours;
                    }
                }
                return store;
            });

            // Flatten ATS information for MyStore and prepare store hours
            if (response.hasOwnProperty('myStore') && response.myStore) {
                response.myStore.ats = response.myStore.hasOwnProperty('ats') && response.myStore.ats.hasOwnProperty(vm.productId) &&
                    response.myStore.ats[vm.productId].hasOwnProperty('quantity') ? response.myStore.ats[vm.productId].quantity : 0;

                response.myStore.hasProduct = response.myStore.ats > 0;
                try {
                    response.myStore.storeOpeningHours = JSON.parse(response.myStore.storeHours);
                } catch (e) {
                    response.myStore.storeOpeningHours = response.myStore.storeHours;
                }
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'ce.misc',
                'eventCategory': 'MISC',
                'eventAction': 'Product Availability',
                'eventLabel': 'enter',
                'eventValue': undefined,
                'nonInteraction': false,
                'selection': response.stores[0].city
            });

            return response;
        }

        /**
         * @description Method to load more stores to show
         * @returns {void}
         * @memberOf jwsdwStore.jwsdwStoreAvailabilityList.StoreAvailabilityListController
         */
        function showMore() {
            // get store list
            vm.currentPage++;
            vm.isMoreLoading = true;
            storeAvailabilityService.list(currentProductId, vm.position || vm.defaultPosition, vm.limit, vm.stores.length + (vm.myStore ? 1 : 0)).then(success, error);

            /**
             * @description Method on success for the store availability show more request
             * @param {Object} response response from store availability service
             * @returns {void}
             */
            function success(response) {
                // Prepare the response
                response = _prepareResponse(response);

                // Show "more button" if at least the number of requested stores were returned
                vm.moreStoresAvailable = response.stores.length >= (vm.limit - (response.myStore ? 1 : 0));

                vm.isMoreLoading = false;
                vm.stores = vm.stores.concat(response.stores);
            }

            /**
             * @description Method on error for the store availability show more request
             * @returns {void}
             */
            function error() {
                vm.isMoreLoading = false;
            }
        }

        /**
         * @description Method to open reserver in store picker
         * @param {Object} store store object
         * @returns {void}
         * @memberOf jwsdwStore.jwsdwStoreAvailabilityList.StoreAvailabilityListController
         */
        function selectStore(store) {
            window.jwsdwMediator.publish('openPicker', 'reserveInStorePicker', {
                'productId': vm.productId,
                'store': store,
                'level': 2
            }, true);
        }

        /**
         * @description Method to update the store list
         * @returns {void}
         * @memberOf jwsdwStore.jwsdwStoreAvailabilityList.StoreAvailabilityListController
         */
        function _update() {
            // clear current store list (so no store list is visible while loading the new one)
            vm.stores = null;

            // get store list
            vm.isLoading = true;
            vm.serviceUnavailable = false;
            storeAvailabilityService.list(currentProductId, vm.position || vm.defaultPosition, vm.limit, 0).then(success, error);

            /**
             * @description Method on success for the store availability update
             * @param {Object} response response from store availability service
             * @param {Object[]} response.stores array of stores
             * @param {Object} response.myStore mystore object
             * @returns {void}
             */
            function success(response) {
                // Prepare the response
                response = _prepareResponse(response);

                // Show "more button" if at least the number of requested stores were returned
                vm.moreStoresAvailable = response.stores.length >= (vm.limit - (response.myStore ? 1 : 0));

                vm.isLoading = false;
                vm.stores = response.stores;

                if (response.myStore) {
                    vm.myStore = response.myStore;
                }
            }

            /**
             * @description Method on error for the store availability update
             * @returns {void}
             */
            function error() {
                vm.serviceUnavailable = true;
                vm.isLoading = false;
            }
        }
    }
}(angular));
