(function (angular) {
    'use strict';

    jwspLoad.$inject = ['$parse'];
    angular
        .module('jwsp.picture')
        .directive('jwspLoad', jwspLoad);

    function jwspLoad($parse) {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var fn = $parse(attrs.jwspLoad);
            element.on('load', function (event) {
                setTimeout(function () {
                    scope.$apply(function() {
                        fn(scope, {
                            $event: event
                        });
                    });
                });
            });
        }
    }
}(angular));
