'use strict';

(function (angular) {

    ProductImageController.$inject = ['IMAGE_BASE_URL'];
    angular
        .module('jwsdw.product')
        .directive('jwsdwProductImage', jwsdwProductImage);

    /**
     * @class jwsdwProduct.jwsdwProductImage
     * @description Color selection form
     * @returns {Object} directive object
     */
    function jwsdwProductImage() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'productImage.html',
            'replace': true,
            'scope': {
                'productId': '=jwsdwProductImage',
                'name': '=',
                'width': '=',
                'height': '=',
                'index': '=',
                'format': '=',
                'bgColor': '='
            },
            'controller': ProductImageController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwColorSelect.ProductImageController
     * @description Controller for color select input
     * @param {String} IMAGE_BASE_URL base url to retrieve product images in a specific size
     * @returns {void}
     */
    function ProductImageController(IMAGE_BASE_URL) {
        var vm = this;

        /**
         * @description Method to run the initialization of the product detail module
         * @returns {void}
         */
        this.$onInit = function () {
            var params = [
                'id=' + vm.productId
            ];

            params.push('width=' + vm.width);

            if (vm.height) {
                params.push('height=' + vm.height);
            }

            params.push('index=' + (vm.index || 0));
            params.push('format=' + (vm.format || 'jpg'));

            if (vm.bgColor) {
                params.push('bgColor=' + vm.bgColor);
            }

            vm.imageURL = IMAGE_BASE_URL + '?' + params.join('&');
        };
    }
}(angular));