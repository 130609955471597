'use strict';

(function (angular) {
    trackCustomEvents.$inject = ['$window'];
    angular
        .module('jwsdw.tracking')
        .factory('trackCustomEvents', trackCustomEvents);

    /**
     * @class jwsdwTracking.trackCustomEvents
     * @description Initializes the custom event tracking functionality
     * @param {Object} $window browser window object
     * @constructor
     */
    function trackCustomEvents($window) {

        var service = {
            'send': send,
            'getUrlParam': getUrlParam
        };

        return service;

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @returns {void}
         */
        function send() {
            $window.jwsdwApi.tracking.fireEvent(getUrlParam('a_cEvent'), {
                'type': getUrlParam('a_cEvent')
            });
        }

        /**
         * @description Method to get url params
         * @param {String} name of parameter in url
         * @returns {String|Boolean} parameter value
         */
        function getUrlParam(name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);

            if (results && results !== null) {
                return results[1] || false;
            }

            return false;
        }
    }
}(angular));