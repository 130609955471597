'use strict';

(function (angular) {

    FrameworkController.$inject = ['$scope', '$window', '$document', '$location', '$sce', '$timeout', 'settings', 'searchSuggestService', 'trackSwatchChange', 'trackCustomEvents'];
    angular
        .module('jwsdw.framework')
        .config(['$locationProvider', function($locationProvider) {
            $locationProvider.html5Mode({
                'enabled': true,
                'rewriteLinks': false
            });
        }])
        .controller('FrameworkController', FrameworkController);

    /**
     * @class jwsdwFramework.FrameworkController
     * @description Initializes the framework functionality
     * @param {Object} $scope current scope
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $document document service that provides access to the document object
     * @param {Object} $location browser location object
     * @param {Object} $sce angular santize service
     * @param {Object} $timeout angular timeout
     * @param {Object} settings settings
     * @param {Object} searchSuggestService searchSuggestService
     * @param {Object} trackSwatchChange tracking service that listens to swatch change events
     * @param {Object} trackCustomEvents tracking service that looks for custom created events
     * @returns {void}
     */
    function FrameworkController($scope, $window, $document, $location, $sce, $timeout, settings, searchSuggestService, trackSwatchChange, trackCustomEvents) { // eslint-disable-line max-len, max-params
        var framework = this,
            $jwsdwBody = angular.element('#jwsdw-body'),
            $body = angular.element('body');

        framework.baseUrl = settings.baseUrl || '/';

        framework.cartCount = settings.cartCount || 0;
        framework.wishlistCount = settings.wishlistCount || 0;

        framework.viewport = null;

        framework.isMenuSmallVisible = false;

        framework.isSearchVisible = false;
        framework.searchQuery = '';
        framework.currentSearchQuery = '';
        framework.searchSuggestions = null;
        framework.searchSuggestionsLoading = false;

        framework.openSearch = openSearch;
        framework.closeSearch = closeSearch;
        framework.onSearchSubmit = onSearchSubmit;
        framework.doSearch = doSearch;
        framework.openMenuNavigation = openMenuNavigation;

        if (window.jwsdwApi.barcodeScanner.canUseBarcodeScanner()) {
            framework.canUseBarcodeScanner = true;
            framework.initBarcodeScanner = initBarcodeScanner;
        }

        /**
         * Activate function
         * @returns {void}
         */
        this.$onInit = function () {
            initSearch();

            if ($location.search() && $location.search().openInfoPicker) {
                $window.jwsdwMediator.publish('openPicker', 'infoPicker', {
                    'cid': $location.search().openInfoPicker
                });
                if (_browserSupportsHistoryAPI()) {
                    $location.search('openInfoPicker', null);
                }
            }
            $window.jwsdwMediator.subscribe('swatchChange', function(data) {
                $timeout(function() {
                    $scope.$apply(function() {
                        trackSwatchChange.send(data);
                    });
                });
            });
            $window.jwsdwMediator.subscribe('setCartCount', function(count) {
                $timeout(function() {
                    $scope.$apply(function() {
                        framework.cartCount = count;
                    });
                });
            });
            $window.jwsdwMediator.subscribe('setWishlistCount', function(count) {
                $timeout(function() {
                    $scope.$apply(function() {
                        framework.wishlistCount = count;
                        settings.wishlistCount = count;
                    });
                });
            });
            $window.jwsdwMediator.subscribe('updateWishlist', function(wishlist) {
                $timeout(function() {
                    $scope.$apply(function() {
                        framework.wishlist = wishlist;
                        settings.wishlist = wishlist;
                        $window.jwsdwMediator.publish('wishlist:markTiles');
                    });
                });
            });
            $window.jwsdwMediator.subscribe('closePicker', function(id) {
                $timeout(function() {
                    $scope.$apply(function() {
                        if (id === 'searchSuggestions') {
                            closeSearch(true, false);
                        }
                    });
                });
            });

            $scope.$watch('framework.viewport', function (viewport) {
                if (framework.isSearchVisible && (viewport.indexOf('large') > -1 || viewport.indexOf('full') > -1) && !framework.searchSuggestions) {
                    closeSearch(false, true);
                }
            });

            trackCustomEvents.send();
        };

        /**
         * Method initialize search
         * @returns {void}
         */
        function initSearch() {
            $scope.$watch('framework.searchQuery', function (query) {
                if (searchSuggestService.isValidSearchString(query)) {
                    if ($('#jwsdw-hamburgerMenu-navigation').length) {
                        $('#jwsdw-hamburgerMenu-navigation').hide();
                    }
                    framework.searchSuggestionsLoading = true;
                    searchSuggestService.getSearchSuggestions(query).then(success, error);
                } else {
                    // close search panel if the search query was not valid
                    if (framework.isSearchVisible && (framework.viewport.indexOf('large') > -1 || framework.viewport.indexOf('full') > -1)) {
                        closeSearch(false, true);
                    }
                    if ($('#jwsdw-hamburgerMenu-navigation').length) {
                        $('#jwsdw-hamburgerMenu-navigation').show();
                    }
                    framework.searchSuggestions = null;
                }
            });

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                framework.searchSuggestions = $sce.trustAsHtml(response);
                framework.searchSuggestionsLoading = false;
                // handle opening and closing of search panel on large viewports
                // trigger openSearch when the search overlay is not yet opened and the search query returned results
                // trigger closeSearch when the search overlay is visible but the search query returned no results
                if (framework.viewport.indexOf('large') > -1 || framework.viewport.indexOf('full') > -1) {
                    if (!framework.isSearchVisible) {
                        openSearch(true);
                    } else if (framework.isSearchVisible && !framework.searchSuggestions) {
                        closeSearch(false, true);
                    }
                }
            }

            /**
             * Method called on error
             * @returns {void}
             */
            function error() {
                framework.searchSuggestions = null;
                framework.searchSuggestionsLoading = false;
            }
        }

        /**
         * @description Method to open menu navigation
         * @returns {void}
         */
        function openMenuNavigation() {
            window.jwsdwMediator.publish('openPicker', 'hamburgerMenuPicker', {
                'level': 1
            });
        }

        /**
         * @description Method to open search overlay and publish event if parameter publish is set to true
         * @param {Boolean} publish publish event if set to true
         * @returns {void}
         */
        function openSearch (publish) {
            framework.isSearchVisible = true;

            if (framework.viewport.indexOf('large') === -1 && framework.viewport.indexOf('full') === -1) {
                $jwsdwBody.addClass('jws-visuallyHidden');
            } else {
                $body.addClass('jws-overflowYHidden jwsdw-pickerOpen');
                $body.css('paddingRight', ($window.jwsdwUtil.uiUtils.isScrollbarVisible() ? $window.jwsdwUtil.uiUtils.getScrollbarWidth($body) : 0) + 'px');
                $('.jwsdw-header-sticky').css('paddingRight', ($window.jwsdwUtil.uiUtils.isScrollbarVisible() ? $window.jwsdwUtil.uiUtils.getScrollbarWidth($body) : 0) + 'px');
            }

            if (publish) {
                $window.jwsdwMediator.publish('searchSuggestions');
            }


            window.scriptOnLoad();

            $document.on('click', clearSearchEvent);
        }

        /**
         * @description Method to activate Barcode scanner
         * @param {String} text text to display for description
         * @returns {void}
         */
        function initBarcodeScanner(text) {
            window.jwsdwApi.barcodeScanner.initBarcodeScanner(text.join('<br/><br/>'), function(data) {
                $timeout(function() {
                    $scope.$apply(function() {
                        framework.searchQuery = data.codeResult.code;
                    });
                });
            });
        }

        /**
         * @description Method to close search overlay and publish event if parameter publish is set to true
         * @param {Boolean} clearQuery clears the search quersy if set to true otherwise not
         * @param {Boolean} publish publish event if set to true
         * @returns {void}
         */
        function closeSearch (clearQuery, publish) {
            framework.isSearchVisible = false;

            if (window.Quagga) {
                window.Quagga.stop();
            }

            if (framework.viewport.indexOf('large') === -1 && framework.viewport.indexOf('full') === -1) {
                $jwsdwBody.removeClass('jws-visuallyHidden');
            } else {
                $body.removeClass('jws-overflowYHidden jwsdw-pickerOpen');
                $body.css('paddingRight', 0);
                $('.jwsdw-header-sticky').css('paddingRight', 0);
            }

            if (clearQuery) {
                framework.searchQuery = '';
            }
            if (publish) {
                $window.jwsdwMediator.publish('searchSuggestions');
            }

            $document.on('click', clearSearchEvent);
        }

        /**
         * Event handler to close search overlay when user clicks outside of the element
         * @param {Object} e event object
         * @returns {void}
         */
        function clearSearchEvent (e) {
            var el = e.target,
                isClickOutside = true;

            do {
                if (el.className) {
                    isClickOutside = el.className.indexOf('jwsdw-header-toolbarSearch') === -1 &&
                        el.className.indexOf('jwsdw-header-searchSuggest-wrapper') === -1;
                }
                el = el.parentNode;
            } while (isClickOutside && el);

            if (isClickOutside) {
                $timeout(function() {
                    $scope.$apply(function() {
                        closeSearch(true, true);
                        $document.off('click', closeSearch);
                    });
                });
            }
        }

        angular.element($window).bind('resize', function() {
            if (framework.isSearchVisible === true) {
                $timeout(function () {
                    if ((framework.viewport.indexOf('large') > -1 || framework.viewport.indexOf('full') > -1) && $jwsdwBody.hasClass('jws-visuallyHidden')) {
                        $jwsdwBody.removeClass('jws-visuallyHidden');
                    }
                });
            }
        });

        /**
         * @description Method to check if enter key was pressed and if so, perform search submit
         * @param {String} searchQuery searchQuery
         * @param {string} baseUrl the base url
         * @returns {void}
         */
        function onSearchSubmit(searchQuery, baseUrl) {
            doSearch(searchQuery, baseUrl);
        }

        /**
         * Method activate search by redirect
         * @param {String} searchQuery searchQuery
         * @param {string} baseUrl the base url
         * @returns {void}
         */
        function doSearch(searchQuery, baseUrl) {
            // check if search term is provided
            if (searchQuery.length > 0) {
                $window.location.href = baseUrl + 'search?q=' + $window.encodeURIComponent(searchQuery);
            }
        }

        /**
         * @description Method to check if the browser supports HTML5 HistoryAPI.
         * @returns {Boolean} true if the browser supports the html 5 history API false otherwise
         * @memberOf jwsdwStoreDetail.StoreDetailController
         */
        function _browserSupportsHistoryAPI() {
            return $window.history && $window.history.pushState;
        }
    }
}(angular));
