'use strict';

(function (angular) {
    BonusProductChoiceController.$inject = ['$scope', '$window', '$q', '$http', '$timeout', 'productService', 'trackAddToBasketService'];
    angular
        .module('jwsdw.picker')
        .controller('BonusProductChoiceController', BonusProductChoiceController);

    /**
     * @class jwsdwPicker.BonusProductChoiceController
     * @param {Object} $scope current scope
     * @param {Object} $window browser window object
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $timeout timeout service provided through angular
     * @param {Object} productService service to provide product information
     * @param {Object} trackAddToBasketService trackAddToBasketService
     * @description Controller for bonus product selection from bonus product choice promotion
     * @constructor
     */
    function BonusProductChoiceController($scope, $window, $q, $http, $timeout, productService, trackAddToBasketService) {
        var bonusProductChoiceCtrl = this;

        bonusProductChoiceCtrl.productData = {};
        bonusProductChoiceCtrl.bonusProducts = {};
        bonusProductChoiceCtrl.selectedProduct = null;
        bonusProductChoiceCtrl.productDataReady = false;
        bonusProductChoiceCtrl.sizes = [];
        bonusProductChoiceCtrl.colors = [];

        bonusProductChoiceCtrl.productService = productService;

        bonusProductChoiceCtrl.getBonusProducts = getBonusProducts;
        bonusProductChoiceCtrl.confirmSelection = confirmSelection;
        bonusProductChoiceCtrl.dismiss = dismiss;

        /**
         * @description Method to initialize controller and set watchers
         * @returns {void}
         */
        this.$onInit = function () {
            $scope.$watch('picker.bonusProductSelectionPicker', getBonusProducts);
            $window.jwsdwMediator.subscribe('changeColor', function(colorId) {
                if (!bonusProductChoiceCtrl.selectedSize || !colorId) {
                    return;
                }
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(bonusProductChoiceCtrl.selectedSize.id, colorId);
                    });
                });
            });
            $window.jwsdwMediator.subscribe('changeSize', function(sizeId) {
                if (!bonusProductChoiceCtrl.selectedColor || !sizeId) {
                    return;
                }
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(sizeId, bonusProductChoiceCtrl.selectedColor.id);
                    });
                });
            });
            $window.jwsdwMediator.subscribe('closePicker', function(type) {
                if (type === 'bonusProductSelectionPicker') {
                    openCartPicker();
                }
            });
        };

        /**
         * @description Method to get the bonusd products
         * @param {String} productData object containing bonus products and products that were added to the cart
         * @returns {void}
         */
        function getBonusProducts(productData) {
            if (productData) {
                bonusProductChoiceCtrl.productData = productData;
                if (productData && productData.hasOwnProperty('selectableBonusProducts')) {
                    productService.getProducts(productData.selectableBonusProducts).then(success);
                }
            }

            /**
             * Method if the product request was successful
             * @param {Object[]} bonusProductData response from the product service
             * @returns {void}
             */
            function success(bonusProductData) {
                bonusProductData = bonusProductData.filter(function (bonusProduct) {
                    return bonusProduct.orderable;
                });
                bonusProductChoiceCtrl.variants = _parseVariants(bonusProductData);
                bonusProductChoiceCtrl.bonusProduct = bonusProductData[0];
                bonusProductChoiceCtrl.selectedProduct = bonusProductData[0];
                bonusProductChoiceCtrl.selectedSize = {
                    'name': bonusProductChoiceCtrl.selectedProduct.sizeName,
                    'id': bonusProductChoiceCtrl.selectedProduct.size,
                    'orderable': bonusProductChoiceCtrl.selectedProduct.stock > 0
                };
                bonusProductChoiceCtrl.selectedColor = {
                    'name': bonusProductChoiceCtrl.selectedProduct.color,
                    'id': bonusProductChoiceCtrl.selectedProduct.colorId,
                    'orderable': bonusProductChoiceCtrl.selectedProduct.stock > 0
                };
                bonusProductChoiceCtrl.productDataReady = true;
            }
        }

        /**
         * @description Method to parse variant sizes and colors
         * @param {Object[]} variants product variants
         * @returns {Object[]} variants
         */
        function _parseVariants(variants) {
            variants = variants
                .map(function(variant) {
                    // product needs to have a price to be orderable
                    if (!variant.hasOwnProperty('price') || !variant.price) {
                        variant.orderable = false;
                    }
                    return variant;
                })
                .map(function (variant) {
                    var rgb;

                    if (!bonusProductChoiceCtrl.sizes.find(function(size) {
                        return size.id === variant.size;
                    })) {
                        bonusProductChoiceCtrl.sizes.push({
                            'name': variant.sizeName,
                            'id': variant.size,
                            'orderable': variant.orderable
                        });
                    }
                    if (!bonusProductChoiceCtrl.colors.find(function(color) {
                        return color.id === variant.colorId;
                    })) {
                        rgb = variant.colors.find(function(color) {
                            return color.id === variant.colorId;
                        });
                        bonusProductChoiceCtrl.colors.push({
                            'name': variant.color,
                            'id': variant.colorId,
                            'rgb': rgb.rgb,
                            'productId': variant.id,
                            'orderable': variant.orderable
                        });
                    }

                    return variant;
                });
            return variants;
        }

        /**
         * @description Method to change product based on color and size IDs
         * @param {String} sizeId size ID
         * @param {String} colorId color ID
         * @returns {void}
         * @private
         */
        function _changeProduct(sizeId, colorId) {
            bonusProductChoiceCtrl.selectedProduct = bonusProductChoiceCtrl.variants.find(function(variation) {
                return variation.size === sizeId && variation.colorId === colorId;
            }) || bonusProductChoiceCtrl.selectedProduct;
        }

        /**
         * Method that is triggered by the user after selection is done.
         * @returns {Promise} promise that triggers follow up actions when successful
         */
        function confirmSelection() {
            var defer = $q.defer(),
                params = {
                    'pids': [bonusProductChoiceCtrl.selectedProduct.id]
                };

            params[window.jwsdwSettings.csrfTokenName] = window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': $window.app.urls.createBonusProductLineItems,
                'params': params
            }).then(success, error);

            return defer.promise;

            /**
             * Success Method triggers further control flow
             * @param {Object} response response object
             * @param {Object} response.data response data
             * @param {Object[]} response.data.bonusProducts bonus products
             * @returns {void}
             */
            function success(response) {
                // if on pdp add bonus product(s) to product data to be shown in cart picker
                if (bonusProductChoiceCtrl.productData.hasOwnProperty('products') &&
                    bonusProductChoiceCtrl.productData.products &&
                    bonusProductChoiceCtrl.productData.products.length > 0) {
                    bonusProductChoiceCtrl.productData.products = bonusProductChoiceCtrl.productData.products
                        .concat(response.data.bonusProducts)
                        .filter(window.jwsdwUtil.arrayUtils.unique('id'));
                }

                // on basket page update the basket to display chosen bonus product(s)
                if (bonusProductChoiceCtrl.productData.hasOwnProperty('isBasketPage') && bonusProductChoiceCtrl.productData.isBasketPage) {
                    setTimeout(function () {
                        $window.jwsdwMediator.publish('updateBasket');
                    });
                }

                $window.jwsdwMediator.publish('closePicker', 'bonusProductSelectionPicker', true);
            }

            /**
             * Error Method rejects the deferred promise
             * @returns {void}
             */
            function error() {
                defer.reject();
            }
        }

        /**
         * Method that is triggered if the user does not want a bonus product.
         * @param {Boolean} doNotShowAgain determines if a cookie is set that prevents the picker from reopening
         * @returns {void}
         */
        function dismiss(doNotShowAgain) {
            if (doNotShowAgain) {
                $window.sessionStorage.setItem('jwsdw-choiceOfBonusProductPicker', 'hide');
            }
            $window.jwsdwMediator.publish('closePicker', 'bonusProductSelectionPicker', true);
        }

        /**
         * @description Method to open cart picker if product data is available. This method is triggered through the listener that is triggered
         * when the bonus product picker is closed.
         * @returns {void}
         */
        function openCartPicker () {
            if (bonusProductChoiceCtrl.productData.hasOwnProperty('products') &&
                bonusProductChoiceCtrl.productData.products &&
                bonusProductChoiceCtrl.productData.products.length > 0) {
                setTimeout(function () {
                    trackAddToBasketService.send(bonusProductChoiceCtrl.productData);
                    $window.jwsdwMediator.publish('setCartCount', bonusProductChoiceCtrl.productData.totalItems);
                    $window.jwsdwMediator.publish('openPicker', 'cartPicker', bonusProductChoiceCtrl.productData);
                });
            }
        }
    }
}(angular));
