'use strict';

(function (angular) {
    angular
        .module('jwsdw.util')
        .filter('truncate', truncate);

    /**
     * @class jwsdwUtil.truncate
     * @description Method to truncate text to given length
     * @returns {Function} filter function to truncate text
     */
    function truncate() {
        /**
         * @description Method to truncate text to given length
         * @param {String} string string to be truncated
         * @param {Number} length length to truncate
         * @returns {String} truncated string
         * @private
         */
        var _truncate = function(string, length) {
            string = string.replace(/&#13;|\s+|"/g, " ");

            while (string.length > 1 && string.lastIndexOf(' ') >= length - 1) {
                string = string.substr(0, string.lastIndexOf(' ')) + '…';
            }
            return string;
        };
        return _truncate;
    }
}(angular));