'use strict';

(function (angular) {
    trackSwatchChange.$inject = ['$window'];
    angular
        .module('jwsdw.tracking')
        .factory('trackSwatchChange', trackSwatchChange);

    /**
     * @class jwsdwTracking.trackSwatchChange
     * @description Initializes the swatch change tracking functionality
     * @param {Object} $window browser window object
     * @constructor
     */
    function trackSwatchChange($window) {
        var service = {
            'send': send
        };

        return service;

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @param {Object} data Product information
         * @param {String} data.productName product name
         * @param {String} data.id id
         * @param {Number} data.priceWithoutVAT price without VAT
         * @param {String} data.colorName color name
         * @param {String} data.sizeName size name
         * @param {Number} data.ats products in stock
         * @returns {void}
         */
        function send(data) {
            $window.jwsdwApi.tracking.fireEvent('swatchChange', {
                'type': 'swatchChange',
                'productname': data.productName,
                'productid': data.id,
                'productPriceWithoutVAT': data.priceWithoutVAT,
                'productStatus': 'view',
                'productColorName': data.colorName,
                'productSizeName': data.sizeName,
                'availableProducts': data.ats
            });
        }
    }
}(angular));