/**
 * @namespace product
 */
'use strict';

(function (angular) {

    config.$inject = ['$compileProvider'];
    angular.module('jwsdw.product', [
        'jwsdw.services'
    ]).config(config);

    /**
     * Config function that specifies configuration for the jwsdw product detail module.
     * @param {Object} $compileProvider compile provider disables adding of internal debug info.
     * @returns {void}
     */
    function config($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    }
}(angular));