'use strict';

(function (angular) {
    angular
        .module('jwsdw.util')
        .directive('jwsdwFormatPrice', jwsdwFormatPrice);

    /**
     * @class jwsdwUtil.jwsdwFormatPrice
     * @description Initializes format price utils
     */
    function jwsdwFormatPrice() {
        var directive = {
            'restrict': 'A',
            'scope': {
                'price': '=',
                'priceFrom': '@'
            },
            'link': formatPrice
        };

        return directive;

        /**
         * @description Method to get a formatted price with decimal values, thousends seaparator and currency from a price provided as number.
         * The logic replaces a formatted price template provided by the backend with formatted version of the provided price.
         * It extracs decimal and thousends separator from the template and uses them on the price number value.
         * @param {Object} scope scope of the directive
         * @param {Object} element representation of the html element that uses the directive.
         * @returns {void}
         */
        function formatPrice(scope, element) {
            scope.priceFrom = scope.priceFrom || '';
            scope.$watch('price', function(price) {
                // get the separators from the formatted number string returned from demandware in jwsdwSettings
                var formattedPrice = window.jwsdwUtil.formatPrice(price);

                if (formattedPrice !== 'N/A') {
                    formattedPrice = scope.priceFrom + ' ' + formattedPrice;
                }
                element.html(formattedPrice);
            });
        }
    }
}(angular));