'use strict';

(function (angular) {
    storeService.$inject = ['$q', '$http', '$log', '$window', '$timeout', 'locationService', 'BASE_URL'];
    angular
        .module('jwsdw.services')
        .factory('storeService', storeService);

    /**
     * @class jwsdwProductDetail.storeService
     * @description Handles the request for getting store information
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $window browser window object
     * @param {Object} $timeout timeout service provided through angular
     * @param {Object} locationService location service
     * @param {String} BASE_URL base url for service requests
     * @returns {Object} service object that returns public methods
     */
    function storeService($q, $http, $log, $window, $timeout, locationService, BASE_URL) {
        var service = {
            'search': search,
            'getStores': getStores,
            'searchByAddress': searchByAddress,
            'searchByLocation': searchByLocation,
            'getStoreAddress': getStoreAddress,
            'getStoreType': getStoreType,
            'isStoreOpen': isStoreOpen,
            'storeOpenFor': storeOpenFor
        };

        return service;

        /**
         * @description Method to submit the provided data
         * @param {Object} position Current position object with position.latitude and position.longitude
         * @param {Number} position.lat latitude
         * @param {Number} position.lng longitude
         * @param {Object} params preset params
         * @returns {object} promise promise with the response data for store
         * @memberOf jwsdwServices.storeService
         */
        function search(position, params) {
            var defer = $q.defer();

            params = params || {};

            // construct parameters object for http request
            params.lat = position.lat;
            params.lng = position.lng;

            $log.debug('jwsdw.services.storeService: List stores with following params: ', params);

            $http({
                'method': 'GET',
                'url': BASE_URL + '/Store-FindStores',
                'params': params
            }).then(success, error);

            return defer.promise;

            /**
             * @description Method on success for the store request
             * @param {Object} response response from store service
             * @returns {void}
             */
            function success(response) {
                var data = (response && response.data) ? response.data : {};

                $log.debug('jwsdw.services.storeService: Store request successful:', data);

                defer.resolve(data);
            }

            /**
             * @description Method on error for the store request
             * @param {Object} response error response from store service
             * @returns {void}
             */
            function error(response) {
                defer.reject(response.data);
            }
        }

        /**
         * @description Method to submit the provided data
         * @param {String[]} storeIds array of store Ids
         * @param {String} countryCode country code
         * @returns {Object} promise promise with the response data for stores
         * @memberOf jwsdwServices.storeService
         */
        function getStores(storeIds, countryCode) {
            var defer = $q.defer(),
                params = {};

            // construct parameters object for http request
            params.stores = storeIds.join(',');

            if (countryCode) {
                params.countryCode = countryCode;
            }
            $log.debug('jwsdw.services.storeService: List stores with following params: ', params);

            $http({
                'method': 'GET',
                'url': BASE_URL + '/Store-GetStores',
                'params': params
            }).then(success, error);

            return defer.promise;

            /**
             * @description Method on success for the store request
             * @param {Object} response response from store service
             * @returns {void}
             */
            function success(response) {
                var data = (response && response.data) ? response.data : {};

                $log.debug('jwsdw.services.storeService: Store request successful:', data);

                defer.resolve(data);
            }

            /**
             * @description Method on error for the store request
             * @param {Object} response error response from store service
             * @returns {void}
             */
            function error(response) {
                defer.reject(response);
            }
        }

        /**
         * @description Method to submit the provided data
         * @param {String} address address
         * @param {Boolean} currentCountry true if the current search should only return stores from the current country
         * @param {String} srvc filter stores by whether the store service is offered
         * @returns {Object} promise promise with the response data for store availability
         * @memberOf jwsdwServices.storeService
         */
        function searchByAddress(address, currentCountry, srvc) {
            var defer = $q.defer(),
                position;

            locationService.getGeolocation(address).then(success, error);

            /**
             * @description Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                position = response;
                search(position, {'currentCountry': currentCountry, 'service': srvc}).then(searchSuccess, error);
            }

            /**
             * @description Method called search success
             * @param {Object} response response object
             * @returns {void}
             */
            function searchSuccess(response) {
                defer.resolve({
                    'position': position,
                    'stores': response
                });
            }

            /**
             * @description Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function error(err) {
                defer.resolve(err);
            }

            return defer.promise;
        }

        /**
         * @description Method to submit the provided data
         * @param {Number} lat latitude
         * @param {Number} lng longitude
         * @param {Boolean} currentCountry true if the current search should only return stores from the current country
         * @param {String} srvc filter stores by whether the store service is offered
         * @returns {Object} promise promise with the response data for store availability
         * @memberOf jwsdwServices.storeService
         */
        function searchByLocation(lat, lng, currentCountry, srvc) {
            var defer = $q.defer(),
                position = {'lat': lat, 'lng': lng};

            search(position, {'currentCountry': currentCountry, 'service': srvc}).then(searchSuccess, error);

            /**
             * @description Method called search success
             * @param {Object} response response object
             * @returns {void}
             */
            function searchSuccess(response) {
                defer.resolve({
                    'position': position,
                    'stores': response
                });
            }

            /**
             * @description Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function error(err) {
                defer.reject(err);
            }

            return defer.promise;
        }

        /**
         * @param {Object} store store object
         * @param {String} store.postalCode postal code
         * @param {String} store.address1 address1
         * @param {String} store.city city
         * @returns {String} address
         */
        function getStoreAddress(store) {
            return store.postalCode + ' ' + store.address1 + ' ' + store.city;
        }

        /**
         * @param {Object} store store object
         * @param {String} store.storeType store type
         * @returns {String} storeType
         */
        function getStoreType(store) {
            if (/OwnStore/i.test(store.storeType)) {
                return 'OwnStore';
            } else if (/Franchise/i.test(store.storeType)) {
                return 'Franchise';
            } else if (/outlet/i.test(store.storeType)) {
                return 'Outlet';
            }
            return 'Retailer';
        }

        /**
         * @param {Object[]} storeOpeningHours object of store hours by day of the week
         * @param {Number} storeOpeningHours.fromHour opening time by hour
         * @param {Number} storeOpeningHours.fromMinute opening time by minute
         * @param {Number} storeOpeningHours.toHour closing time by hour
         * @param {Number} storeOpeningHours.toMinute closing time by hour
         * @returns {Object} object containing hours and minutes the store is still open for
         */
        function storeOpenFor(storeOpeningHours) {
            var now = new Date(),
                currentDay = now.getDay(),
                openFor;

            if (currentDay === 0) {
                currentDay = 7;
            }

            if (storeOpeningHours) {
                storeOpeningHours[currentDay].forEach(function(openingTimes) {
                    var from = new Date(),
                        to = new Date();

                    from.setHours(openingTimes.fromHour);
                    from.setMinutes(openingTimes.fromMinute);
                    to.setHours(openingTimes.toHour);
                    to.setMinutes(openingTimes.toMinute);

                    if (to > from) {
                        // Example: From 08:00 & to 20:00
                        if (now >= from && now < to) {
                            openFor = {
                                'hours': to.getHours() - now.getHours(),
                                'minutes': to.getMinutes() - now.getMinutes()
                            };
                        }
                    } else {
                        // Example: From 08:00 & to 01:00
                        if (now >= from) {
                            // Now is before midnight
                            openFor = {
                                'hours': 24 - now.getHours() + to.getHours(),
                                'minutes': to.getMinutes() - now.getMinutes()
                            };
                        } else if (now <= to) {
                            // Now is after midnight
                            openFor = {
                                'hours': to.getHours() - now.getHours(),
                                'minutes': to.getMinutes() - now.getMinutes()
                            };
                        }
                    }

                    if (openFor && openFor.minutes < 0) {
                        openFor.hours -= 1;
                        openFor.minutes += 60;
                    }
                });
            }

            return openFor || {};
        }

        /**
         * @param {Object[]} storeOpeningHours object of store hours by day of the week
         * @param {Number} storeOpeningHours.fromHour opening time by hour
         * @param {Number} storeOpeningHours.fromMinute opening time by minute
         * @param {Number} storeOpeningHours.toHour closing time by hour
         * @param {Number} storeOpeningHours.toMinute closing time by hour
         * @returns {Boolean} true if open
         */
        function isStoreOpen(storeOpeningHours) {
            var now = new Date(),
                currentDay = now.getDay(),
                open = false;

            if (currentDay === 0) {
                currentDay = 7;
            }

            // Store is open, if there is any "open" opening time for the current day
            if (storeOpeningHours && storeOpeningHours[currentDay]) {
                storeOpeningHours[currentDay].forEach(function(openingTimes) {
                    var from = new Date(),
                        to = new Date();

                    from.setHours(openingTimes.fromHour);
                    from.setMinutes(openingTimes.fromMinute);
                    to.setHours(openingTimes.toHour);
                    to.setMinutes(openingTimes.toMinute);

                    if (to > from) {
                        // Example: From 08:00 & to 20:00
                        if (now >= from && now <= to) {
                            open = true;
                        }
                    } else {
                        // Example: From 08:00 & to 01:00
                        if (now >= from || now <= to) {
                            open = true;
                        }
                    }
                });
            }

            return open;
        }
    }
}(angular));
