'use strict';

(function (angular) {
    storeAvailabilityService.$inject = ['$q', '$http', '$log', '$window', '$timeout', 'BASE_URL'];
    angular
        .module('jwsdw.services')
        .factory('storeAvailabilityService', storeAvailabilityService);

    /**
     * @class jwsdwServices.storeAvailabilityService
     * @description Handles the request for getting store information
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $window browser window object
     * @param {Object} $timeout timeout service provided through angular
     * @param {String} BASE_URL base url for service requests
     * @returns {Object} service object that returns public methods
     */
    function storeAvailabilityService($q, $http, $log, $window, $timeout, BASE_URL) {
        var service = {
            'list': list
        };

        return service;

        /**
         * @description Method to submit the provided data
         * @param {String} productId Product id of current selection
         * @param {Object} position Current position object with position.latitude and position.longitude
         * @param {Number} position.lat latitude
         * @param {Number} position.lng longitude
         * @param {Number} limit Limit for returned stores
         * @param {Number} offset Offset for where to start returning stores
         * @returns {Object} promise promise with the response data for store availability
         * @memberOf jwsdwPicker.storeAvailabilityService
         */
        function list(productId, position, limit, offset) {
            var defer = $q.defer(),
                params = {};

            // construct parameters object for http request
            params.pid = productId;
            params.lat = position.lat;
            params.lng = position.lng;
            params.maxDistance = 100;
            params.limit = limit || 20;
            params.offset = offset || 0;

            $log.debug('jwsdw.picker.storeAvailabilityService: List stores for product with following params: ', params);

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Store-getProductAvailabilityInStores',
                'params': params
            }).then(success, error);

            return defer.promise;

            /**
             * @description Method on success for the store availability request
             * @param {Object} response response from store availability service
             * @param {Object} response.data response data
             * @returns {void}
             */
            function success(response) {
                var data = (response && response.data) ? response.data : {};

                $log.debug('jwsdw.picker.storeAvailabilityService: Store availability request successful:', data);

                defer.resolve(data);
            }

            /**
             * @description Method on error for the store availability request
             * @param {Object} response error response from store availability service
             * @returns {void}
             */
            function error(response) {
                defer.reject(response);
            }
        }
    }
}(angular));
