'use strict';

(function (angular) {
    pickupLocationService.$inject = ['$q', '$log', '$http', '$window', 'BASE_URL'];
    angular
        .module('jwsdw.services')
        .factory('pickupLocationService', pickupLocationService);

    /**
     * @class jwsdwServices.pickupLocationService
     * @description Service to provide access to the pickup location information.
     * @param {object} $q promise service that provides promise functionality
     * @param {object} $log log service that provides logging in angluar
     * @param {object} $http http service that provides an API for ajax calls
     * @param {object} $window browser window object
     * @param {string} BASE_URL base url for service requests
     * @returns {object} service object that returns public methods
     */
    function pickupLocationService($q, $log, $http, $window, BASE_URL) {
        var service;

        service = {
            'getLocationsByAddress': getLocationsByAddress,
            'getLocationsByCoordinate': getLocationsByCoordinate
        };

        return service;

        /**
         * @description method to get packstations results using
         * @param {string} postalCodeOrCity zip code or city
         * @param {string} locationType locationType
         * @return {object} promise
         */
        function getLocationsByAddress(postalCodeOrCity, locationType) {
            var defer = $q.defer(),
                params = {
                    'postalCodeOrCity': postalCodeOrCity,
                    'locationType': locationType
                };
            params[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/PickupLocation-getLocationsByAddress',
                'params': params
            }).then(function (searchResultData) {
                $log.debug('Search successful');
                if (searchResultData.hasOwnProperty('data') && searchResultData.data !== '') {
                    defer.resolve(searchResultData.data);
                } else {
                    defer.resolve(null);
                }
            }, function () {
                defer.resolve(null);
            });

            return defer.promise;
        }

        /**
         * @description method to get packstations results using
         * @param {number} lat latitude
         * @param {number} lng longitude
         * @param {string} locationType locationType
         * @return {object} promise
         */
        function getLocationsByCoordinate(lat, lng, locationType) {
            var defer = $q.defer(),
                params = {
                    'lat': lat,
                    'lng': lng,
                    'locationType': locationType
                };
            params[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/PickupLocation-getLocationsByCoordinate',
                'params': params
            }).then(function (searchResultData) {
                $log.debug('Search successful');
                if (searchResultData.hasOwnProperty('data') && searchResultData.data !== '') {
                    defer.resolve(searchResultData.data);
                } else {
                    defer.resolve(null);
                }
            }, function (error) {
                defer.reject(error.data);
            });

            return defer.promise;
        }
    }
}(angular));
