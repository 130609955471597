'use strict';

(function (angular) {

    settings.$inject = ['$window'];
    angular
        .module('jwsdw.framework')
        .factory('settings', settings);

    /**
     * @class jwsdwFramework.settings
     * @description Method to get jwsdwSettings
     * @param {Object} $window browser window object
     * @returns {Object} jwsdwSettings
     */
    function settings($window) {
        return $window.jwsdwSettings || {};
    }
}(angular));