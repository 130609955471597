'use strict';

(function (angular) {

    authService.$inject = ['$q', '$http', '$window', '$log'];
    angular
        .module('jwsdw.ocapi')
        .factory('authService', authService);

    /**
     * @class jwsdwOCAPI.authService
     * @description OCAPI auth service that provides access to authentication calls
     * against the ocapi returning the authentication bearer token.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @returns {Object} service object that returns public methods
     */
    function authService($q, $http, $window, $log) {
        var ocapiVersion = 'v17_2',
            service,
            _parsedResponse = {};

        service = {
            'getAccessToken': getAccessToken
        };

        return service;

        /**
         * Returns an access token for the ocapi
         * @returns {Object} Promise
         */
        function getAccessToken() {
            var defer = $q.defer();
            if (!_parsedResponse.accessToken) {
                // get access token
                _auth().then(success);
            } else {
                _refresh(_parsedResponse.accessToken).then(success, error);
            }

            return defer.promise;

            /**
             * Function on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                $log.debug('jwsdw.ocapi: Authentication successful (Access token:', response.headers().authorization, ')');
                _parsedResponse.customerId = response.data.customer_id;
                _parsedResponse.accessToken = response.headers().authorization;
                defer.resolve(_parsedResponse);
            }

            /**
             * Function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                $log.debug('jwsdw.ocapi: Authentication error (Error data:', response.data, ')');
                defer.reject(response.data);
            }
        }

        /**
         * Function to call the ocapi auth service to retrieve a JWT Token
         * @returns {Object} Promise of the http call
         * @private
         */
        function _auth() {
            $log.debug('jwsdw.ocapi: Authenticate to OCAPI');

            return $http({
                'method': 'POST',
                'withCredentials': true,
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/customers/auth',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'params': {
                    'client_id': $window.jwsdwSettings.clientId
                },
                'data': {
                    'type': 'session'
                }
            });
        }

        /**
         * Function to call the ocapi auth service to retrieve a new JWT Token to prevent expiring
         * @param {String} accessToken access token
         * @returns {Object} Promise of the http call
         * @private
         */
        function _refresh(accessToken) {
            $log.debug('jwsdw.ocapi: Refresh JWT Token');

            return $http({
                'method': 'POST',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/customers/auth',
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                'params': {
                    'client_id': $window.jwsdwSettings.clientId
                },
                'data': {
                    'type': 'refresh'
                }
            });
        }
    }
}(angular));