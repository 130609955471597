'use strict';

(function (angular) {
    config.$inject = ['$logProvider', '$httpProvider'];
    angular
        .module('jwsdw.ocapi')
        .config(config);

    /**
     * Config function that specifies configuration for the jwsdw ocapi module.
     * @param {Object} $logProvider enables and disables logging based on jwsdw debug settings.
     * @param {Object} $httpProvider adds http interceptor to the ocapi module
     * @returns {void}
     */
    function config($logProvider, $httpProvider) {
        $logProvider.debugEnabled(window.jwsdwSettings.debug);
        $httpProvider.interceptors.push('httpInterceptor');
    }
}(angular));