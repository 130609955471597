'use strict';

(function (angular) {

    reserveInStoreService.$inject = ['$q', '$http', '$log', '$window', 'generalTracking', 'BASE_URL'];
    angular
        .module('jwsdw.services')
        .factory('reserveInStoreService', reserveInStoreService);

    /**
     * @class jwsdwServices.reserveInStoreService
     * @description Handles the request for getting store information
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $window browser window object
     * @param {Object} generalTracking tracking interface
     * @param {String} BASE_URL base url for service requests
     * @returns {Object} service object that returns public methods
     */
    function reserveInStoreService($q, $http, $log, $window, generalTracking, BASE_URL) {
        var service = {
            'reserve': reserve
        };

        return service;

        /**
         * @description Method to submit the provided data
         * @param {String} productId Product id of current selection
         * @param {String} customerSalutation Salutation of customer
         * @param {String} customerFirstName First name of customer
         * @param {String} customerLastName Last name of customer
         * @param {String} customerEmail E-Mail address of customer
         * @param {String} customerPhone phone number of customer
         * @param {String} storeEmail E-Mail address of store in the jack-wolfskin.com domain
         * @param {String} storeReservationEmail E-Mail address of store where the registration is sent
         * @param {String} storeCity storeCity
         * @param {String} storeType storeType
         * @param {String} storeID storeID
         * @returns {Object} promise promise with the data returned by the reserve in store service
         * @memberOf jwsdwServices.reserveInStoreService
         */
        function reserve(productId, customerSalutation, customerFirstName, customerLastName, customerEmail, customerPhone, storeEmail, storeReservationEmail, storeCity, storeType, storeID) { // eslint-disable-line
            var defer = $q.defer(),
                params = {};

            // construct parameters object for http request
            params.pid = productId;
            params.customerSalutation = customerSalutation;
            params.customerFirstName = customerFirstName;
            params.customerLastName = customerLastName;
            params.storeemail = storeEmail;
            params.storereservationemail = storeReservationEmail;
            params.customeremail = customerEmail;
            params.phone = customerPhone;
            params[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $log.debug('jwsdw.picker.reserveInStoreService: Reserve product in store params: ', params);

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Store-reserveProduct',
                'params': params
            }).then(success, error);

            return defer.promise;

            /**
             * @description Method on success callback for the reserve in store request
             * @param {Object} response response from reserve in store service
             * @returns {void}
             */
            function success(response) {
                generalTracking.send({
                    'action': 'reservationRequest',
                    'type': 'reservationRequest'
                });

                if (response.data.success) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'ce.misc',
                        'eventCategory': 'MISC',
                        'eventAction': 'Product Availability',
                        'eventLabel': 'success',
                        'eventValue': undefined,
                        'nonInteraction': false,
                        'selection': storeType + ' ' + storeCity + ' - ' + storeID
                    });
                    $log.debug('jwsdw.picker.reserveInStoreService: Store availability request successful:', response);
                    defer.resolve(response.data);
                } else {
                    $log.debug('jwsdw.picker.reserveInStoreService: Store availability request failed:', response);
                    defer.reject(response.data);
                }
            }

            /**
             * @description Method on error callback for the reserve in store request
             * @param {Object} response error response from reserve in store service
             * @returns {void}
             */
            function error(response) {
                defer.reject(response);
            }
        }
    }
}(angular));
