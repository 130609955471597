(function (angular) {
    'use strict';

    angular
        .module('jwsp.picture')
        .controller('PictureController', PictureController);

    function PictureController() {
        var image = this;

        // properties
        image.isLoading = true;
        image.zoomInitialized = false;

        //methods
        image.onLoad = onLoad;
        window.jwsdwMediator.subscribe('unloadZoom', function() {
            image.zoomInitialized = false;
        });

        function onLoad(event) {
            image.isLoading = false;
            if (!image.zoomInitialized && event.target.id.split('-')[1] === 'imageViewer') {
                image.zoomInitialized = true;
                window.jwsdwApi.pinch.initPinch(event.target.id.split('-')[0]);
            }
        }
    }
}(angular));
