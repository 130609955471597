'use strict';

(function (angular) {
    ocapiContentService.$inject = ['$window', '$http', '$q'];
    angular
        .module('jwsdw.ocapi')
        .factory('ocapiContentService', ocapiContentService);

    /**
     * @class jwsdwOCAPI.ocapiContentService
     * @description OCAPI product service that provides access to product ocapi resource.
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $q promise service that provides promise functionality
     * @returns {Object} service object that returns public methods
     */
    function ocapiContentService($window, $http, $q) {
        var ocapiVersion = 'v17_2',
            service,
            deferredAbort = $q.defer();

        service = {
            'getContent': getContent,
            'getContentAssets': getContentAssets,
            'cancelGetContent': cancelGetContent
        };

        return service;

        /**
         * @description method to get content by id
         * @param {String} cid content asset id
         * @returns {Object} promise
         */
        function getContent(cid) {
            var deferred = $q.defer();

            // demandware accepts search strings between 3 and 50 characters
            $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/content/' + cid,
                'params': {
                    'client_id': $window.jwsdwSettings.clientId,
                    'locale': $window.jwsdwSettings.locale
                },
                'timeout': deferredAbort.promise
            }).then(success, error);

            return deferred.promise;

            /**
             * function on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                deferred.resolve(response.data);
            }

            /**
             * function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                deferred.reject(response);
            }
        }

        /**
         * @description method to get search suggestions via XHR
         * @param {String[]} cids content asset ids
         * @returns {Promise} promise
         */
        function getContentAssets(cids) {
            var deferred = $q.defer();

            // demandware accepts search strings between 3 and 50 characters
            $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/content/(' + cids.join(',') + ')',
                'params': {
                    'client_id': $window.jwsdwSettings.clientId,
                    'locale': $window.jwsdwSettings.locale
                },
                'timeout': deferredAbort.promise
            }).then(success, error);

            return deferred.promise;

            /**
             * function on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                deferred.resolve(response.data);
            }

            /**
             * function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                deferred.reject(response);
            }
        }

        /**
         * @description method to cancel get search suggestions via XHR
         * @returns {void}
         */
        function cancelGetContent() {
            deferredAbort.resolve();

            deferredAbort = $q.defer();
        }
    }
}(angular));