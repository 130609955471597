'use strict';

(function (angular) {

    angular
        .module('jwsdw.product')
        .directive('jwsdwProductRatingStars', jwsdwProductRatingStars);

    /**
     * @class jwsdwProduct.jwsdwProductRatingStars
     * @description Size selection form
     * @returns {Object} directive
     */
    function jwsdwProductRatingStars() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'productRatingStars.html',
            'replace': true,
            'scope': {
                'productRating': '=jwsdwProductRating'
            },
            'controller': ProductRatingStarsController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwProductRatingStars.ProductRatingStarsController
     * @description Controller for size selection input
     * @constructor
     */
    function ProductRatingStarsController() {}
}(angular));