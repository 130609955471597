'use strict';

(function (angular) {
    customerService.$inject = ['$q', '$window', '$log', 'ocapiCustomerService'];
    angular
        .module('jwsdw.services')
        .factory('customerService', customerService);

    /**
     * @class jwsdwServices.customerService
     * @description Service to provide access functions to the customer information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} ocapiCustomerService service to provide access to the ocapi customer resource
     * @returns {Object} service object that returns public methods
     */
    function customerService($q, $window, $log, ocapiCustomerService) {
        var service;

        service = {
            'getCustomer': getCustomer,
            'updateCustomer': updateCustomer
        };

        return service;

        /**
         * @description Method to get the basket.
         * @param {String} customerId customer ID
         * @returns {Promise} promise with the parsed basket data
         */
        function getCustomer(customerId) {
            var defer = $q.defer();
            ocapiCustomerService.get(customerId).then(function(customerData) {
                defer.resolve(customerData);
            });

            return defer.promise;
        }

        /**
         * @description Method to get the basket.
         * @param {Object} properties map of properties and their values to be updated
         * @returns {Promise} promise with the parsed basket data
         */
        function updateCustomer(properties) {
            var defer = $q.defer();
            ocapiCustomerService.update(properties).then(success, error);

            return defer.promise;

            /**
             * Function on success
             * @param {Object} customerData response containing customer data
             * @returns {void}
             */
            function success(customerData) {
                defer.resolve(customerData);
            }

            /**
             * function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                defer.reject(response);
            }
        }

    }
}(angular));