'use strict';

(function (angular) {
    measurement.$inject = ['NUMBER_FORMAT'];
    angular
        .module('jwsdw.product')
        .filter('measurement', measurement);

    /**
     * @class jwsdwProduct.measurement
     * @description Filter for measurement values. Can convert cm to inch and render ranges
     * @param {String} NUMBER_FORMAT number format pattern
     * @returns {Function} filter to get measurements
     */
    function measurement(NUMBER_FORMAT) {
        /**
         * @description Method to calculate measurement
         * @param {Object|Number} measurementObj measurement object or number
         * @param {String} unit measurement unit
         * @param {Number} [decimalPoints] number of digits after the decimal point
         * @returns {String} measurement
         * @private
         */
        var _measurement = function(measurementObj, unit, decimalPoints) {
            var from, to,
                convertToInch = false;

            if (!measurementObj) {
                return '-';
            }

            if (unit === 'in') {
                convertToInch = true;
            }

            if (typeof measurementObj === 'object') {
                from = measurementObj.from;
                to = measurementObj.to;
                if (convertToInch) {
                    from = from / 2.54;
                    to = to / 2.54;
                }

                from = _applyNumberFormat(from, NUMBER_FORMAT, decimalPoints);
                to = _applyNumberFormat(to, NUMBER_FORMAT, decimalPoints);
                return from + ' - ' + to + ' ' + unit;
            }

            from = measurementObj;
            if (convertToInch) {
                from = from / 2.54;
            }

            from = _applyNumberFormat(from, NUMBER_FORMAT, decimalPoints);

            return from + ' ' + unit;
        };

        /**
         * @description Method to format numeber by pattern
         * @param {Number} number number
         * @param {String} numberFormat number format
         * @param {Number} decimalPoints decimal points
         * @returns {String} formatted number
         */
        function _applyNumberFormat(number, numberFormat, decimalPoints) {
            var numberMatch = /\d+([,.'])\d{3}([.,])\d{2}/.exec(numberFormat),
                round = 1,
                formattedNumberTemplate = numberMatch[0],
                thousendsSeparator = numberMatch[1],
                decimalSeparator = numberMatch[2],
                formattedNumber;

            decimalPoints = decimalPoints || 0;
            round = Math.pow(10, decimalPoints);

            number = Math.round(number * round) / round;

            if (number % 1 !== 0) {
                formattedNumber = number.toFixed(decimalPoints);
            } else {
                formattedNumber = number.toString();
            }

            formattedNumber = formattedNumber.replace('.', decimalSeparator);

            if (number >= 1000) {
                //add a thousends separator before every fourth digit
                formattedNumber = formattedNumber.replace(/(\d)(?=(\d{3})+(?!\d))/, '$1' + thousendsSeparator);
            }
            //replace the dummy price value provided by the backend with the new actual price value while keeping the currency
            formattedNumber = numberFormat.replace(formattedNumberTemplate, formattedNumber);

            return formattedNumber;
        }

        return _measurement;
    }
}(angular));