(function (angular) {
    'use strict';

    escape.$inject = ['$window'];
    angular
        .module('jwsp.util')
        .filter('escape', escape);

    function escape($window) {
        return function(input) {
            if (input) {
                return $window.encodeURIComponent(input);
            }

            return '';
        }
    }
}(angular));
