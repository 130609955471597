'use strict';

(function (angular) {
    LocationController.$inject = ['$scope', '$log', '$window', 'locationService'];
    angular
        .module('jwsdw.picker')
        .controller('LocationController', LocationController);

    /**
     * @class jwsdwPicker.LocationController
     * @description Provides functionality for geolocation
     * @param {Object} $scope current scope
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $window browser window object
     * @param {Object} locationService location service
     * @constructor
     */
    function LocationController($scope, $log, $window, locationService) {
        var vm = this;

        vm.geoLocationError = null;
        vm.location = {};
        vm.loadingGeolocation = false;
        vm.protocol = $window.location.protocol;

        // state of the location service
        // Possible states:
        // 'normal'
        // 'submitted'
        vm.state = 'normal';

        vm.onProvideAdress = onProvideAdress;
        vm.onGeolocateAdress = onGeolocateAdress;

        /**
         * @description Method to run the initialization of the location controller
         * @returns {void}
         * @memberOf jwsdwPicker.LocationController
         */
        this.$onInit = function () {
            $window.jwsdwMediator.subscribe('clearLocation', function() {
                vm.location = {};
            });
        };

        /**
         * @description Method that uses google maps Geocoder to retrieve coordinates for given adress string
         * @param {Object} location location object containing:
         * @param {String} location.adress Current adress string
         * @param {Object} location.position Current position object
         * @param {Number} location.position.lat Latitude of current position
         * @param {Number} location.position.lng Longitude of current position
         * @returns {void}
         * @memberOf jwsdwPicker.LocationController
         */
        function onProvideAdress(location) {
            // reset error from geolocation request if one exists
            vm.geoLocationError = null;
            vm.state = 'normal';

            if (location.address && location.address !== '') {
                locationService.getGeolocation(location.address).then(success, error);
            } else {
                vm.state = 'submitted';
                vm.location.position = null;
            }

            /**
             * Method called on success
             * @param {String} response response containing position object
             * @returns {void}
             */
            function success(response) {
                $log.debug('jwsdw.picker.pickerController: Google Maps API Geocoding request: ', response);
                vm.location.position = response;
            }

            /**
             * Method called on error
             * @returns {void}
             */
            function error() {
                vm.loadingGeolocation = false;
                vm.location.position = null;
            }
        }

        /**
         * @description Method that uses Google's navigator.geolocation.getCurrentPosition() to retrieve current position of user
         * @returns {void}
         * @memberOf jwsdwPicker.LocationController
         */
        function onGeolocateAdress() {
            vm.loadingGeolocation = true;

            $window.navigator.geolocation.getCurrentPosition(function(location) {
                $scope.$apply(function() {
                    vm.geoLocationError = null;
                    vm.location.position = {
                        'lat': location.coords.latitude,
                        'lng': location.coords.longitude
                    };
                });

                _getAddressByCoordinates(vm.location.position);
            }, handlePositionError);

            /**
             * @description Method for failed get location call
             * @param {Object} error error object
             * @returns {void}
             */
            function handlePositionError(error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        // User denied the request for Geolocation
                        vm.geoLocationError = 'PERMISSION_DENIED';
                        break;
                    case error.POSITION_UNAVAILABLE:
                        // Location information is unavailable
                        vm.geoLocationError = 'POSITION_UNAVAILABLE';
                        break;
                    case error.TIMEOUT:
                        // The request to get user location timed out
                        vm.geoLocationError = 'TIMEOUT';
                        break;
                    case error.UNKNOWN_ERROR:
                        // An unknown error occurred
                        vm.geoLocationError = 'UNKNOWN_ERROR';
                        break;
                }
                vm.loadingGeolocation = false;
            }
        }

        /**
         * @description Method that uses google maps Geocoder to retrieve the adress by coordinates
         * @param {Object} position Current position object containing:
         * @param {Number} position.lat Latitude of current position
         * @param {Number} position.lng Longitude of current position
         * @returns {void}
         * @memberOf jwsdwPicker.LocationController
         */
        function _getAddressByCoordinates(position) {
            locationService.getAddressByCoordinates(position.lat, position.lng).then(success, error);

            /**
             * Method called on success
             * @param {String} response response containing address
             * @returns {void}
             */
            function success(response) {
                vm.loadingGeolocation = false;
                vm.location.address = response;
            }

            /**
             * Method called on error
             * @returns {void}
             */
            function error() {
                vm.loadingGeolocation = false;
            }
        }
    }
}(angular));