'use strict';

(function (angular) {
    angular
        .module('jwsdw.util')
        .filter('distance', distance);

    /**
     * @class jwsdwProductDetail.distance
     * @description Filter for distance values. Converts miles to metric values and adds distance unit ('m', 'km').
     * @returns {Function} filter to get metric distance
     */
    function distance() {
        /**
         * @description Method to calculate metric distance from miles and add unit.
         * @param {Number} distanceValue distance value
         * @param {Boolean} inMiles true if the distance value is in miles false otherwise
         * @returns {String} metric distance with unit ('m' if distance < 1000, 'km' otherwise)
         * @private
         */
        var _distance = function(distanceValue, inMiles) {
            var amount = inMiles ? distanceValue * 1609.34 : distanceValue * 1000;

            if (amount < 1000) {
                return Math.round(amount) + ' m';
            }
            return (amount / 1000).toFixed(1) + ' km';
        };
        return _distance;
    }
}(angular));