'use strict';

(function (angular) {
    jwsdwStickyHeader.$inject = ['$window'];
    angular
        .module('jwsdw.framework')
        .directive('jwsdwStickyHeader', jwsdwStickyHeader);

    /**
     * @class jwsdwFramework.jwsdStickyHeader
     * @param {Object} $window the window element
     * @returns {Object} directive
     */
    function jwsdwStickyHeader($window) {
        var directive = {
            'restrict': 'A',
            'link': link
        };

        return directive;

        /**
         * link function
         * @param {Object} scope scope
         * @param {Object} element element
         * @returns {void}
         */
        function link(scope, element) {
            let mediaQuery;
            scope.lastScrollTop = $window.pageYOffset;
            scope.screenWidth = $window.innerWidth;
            scope.stickyHeaderOffset = 60 + ($('#content-bar').height() || 0) + $('.jwsdw-header-module').height() || $('.jwsdw-header-module').height();

            let $isContentBarVisible = $('.jwsdw-isContentBarVisible'),
                $contentBar = $('#content-bar'),
                observer,
                resizeObserver;

            if (!$contentBar.length) {
                $('#jwsdw-body').addClass('jwsdw-bodyPaddingTop'); // added padding to show the breadcrumbs only if the content-bar slot is not available
            }

            if ($contentBar.length) {
                let observerOptions = {
                    'root': null,
                    'threshold': 0,
                    'rootMargin': '0px'
                };

                let observerCallback = function (entries) {
                    entries.forEach(function (entry) {
                        if (entry.intersectionRatio < 1) {
                            element.css('position', '');
                            $('.jwsdw-visibleContentBar').removeClass('jws-hidden');
                        } else {
                            element.css('position', 'initial');
                            $('.jwsdw-visibleContentBar').addClass('jws-hidden');
                        }
                    });
                };

                // used to set the position of the header and the visibility of the placeholder
                observer = new IntersectionObserver(observerCallback, observerOptions);

                observer.observe($isContentBarVisible[0]);

                resizeObserver = new ResizeObserver(() => {
                    scope.stickyHeaderOffset = 60 + ($('#content-bar').height() || 0) + $('.jwsdw-header-module').height() || $('.jwsdw-header-module').height();
                    handleStickyHeaderLogic();
                    scope.$apply();
                });

                resizeObserver.observe($contentBar[0]);
            }

            if (scope.lastScrollTop > scope.stickyHeaderOffset) {
                element.addClass('jwsdw-header-sticky');
                $('body').addClass('jws-has-sticky-header');
                element.find('.jws-hidden').removeClass('jws-hidden');
                $('.jwsdw-header-module .jwsdw-header-searchSuggest-wrapper').addClass('jws-hidden');
            }

            $(element.find('.jwsdw-header-icon-search button')).on('click', function() {
                $(element.find('.jwsdw-header-searchForm')).toggle();
            });
            $(element.find('.jwsdw-header-close-search')).on('click', function() {
                $(element.find('.jwsdw-header-searchForm')).hide();
            });

            $window.addEventListener('scroll', $window.jwsdwUtil.throttle(function() {
                handleStickyHeaderLogic();
            }, 100), $window.jwsdwUtil.supportsPassiveEvents() ? { 'passive': true } : false);

            /* Handling custom logic when switching from mobile to larger viewports and vice-versa */
            mediaQuery = window.matchMedia('(max-width: 899px)');

            if ('addEventListener' in mediaQuery) {
                mediaQuery.addEventListener('change', handleStickyHeaderLogic.bind(mediaQuery, mediaQuery.matches));
            } else {
                mediaQuery.addListener(handleStickyHeaderLogic.bind(mediaQuery, mediaQuery.matches));
            }

            /**
             * @description Helper function to handle sticky header logic on scroll/viewport switch
             * @param {Boolean} isMobile - Parameter to specify whether the current viewport is < 900px
             * @returns {undefined}
             */
            function handleStickyHeaderLogic (isMobile) {
                scope.currentScrollTop = $window.pageYOffset;
                scope.screenWidth = $window.innerWidth;
                scope.isMobile = scope.screenWidth <= 899 || isMobile;

                if (scope.currentScrollTop > scope.stickyHeaderOffset) {
                    if (scope.isMobile) {
                        if (scope.currentScrollTop <= scope.lastScrollTop) {
                            element.addClass('jwsdw-header-sticky').removeClass('jwsdw-header-pristine');
                            $('body').addClass('jws-has-sticky-header');
                            element.find('.jws-hidden').removeClass('jws-hidden');
                            $('.jwsdw-header-module .jwsdw-header-searchSuggest-wrapper').addClass('jws-hidden');
                        } else {
                            element.removeClass('jwsdw-header-sticky');
                            $('body').removeClass('jws-has-sticky-header');
                            element.find('.jwsdw-header-options').addClass('jws-hidden');
                            element.find('.jws-header-logo-container').addClass('jws-hidden');
                            $('.jwsdw-header-module .jwsdw-header-searchSuggest-wrapper').removeClass('jws-hidden');
                        }
                    } else {
                        element.addClass('jwsdw-header-sticky');
                        $('body').addClass('jws-has-sticky-header');
                        element.find('.jws-hidden').removeClass('jws-hidden');
                        $('.jwsdw-header-module .jwsdw-header-searchSuggest-wrapper').addClass('jws-hidden');
                    }
                } else if (scope.isMobile) {
                    element.addClass('jwsdw-header-sticky').removeClass('jwsdw-header-pristine');
                    $('body').addClass('jws-has-sticky-header');
                    return;
                } else {
                    element.removeClass('jwsdw-header-sticky');
                    $('body').removeClass('jws-has-sticky-header');
                    element.find('.jwsdw-header-options').addClass('jws-hidden');
                    element.find('.jws-header-logo-container').addClass('jws-hidden');
                    $('.jwsdw-header-module .jwsdw-header-searchSuggest-wrapper').removeClass('jws-hidden');
                }

                scope.lastScrollTop = scope.currentScrollTop;
                scope.$apply();
            }
        }
    }
}(angular));