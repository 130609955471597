'use strict';

(function (angular) {
    searchService.$inject = ['$q', '$log', '$window', '$http'];
    angular
        .module('jwsdw.services')
        .factory('searchService', searchService);

    /**
     * @class jwsdwServices.searchService
     * @description Service to provide access to the product information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $http http service that provides an API for ajax calls
     * @returns {Object} service object that returns public methods
     */
    function searchService($q, $log, $window, $http) {
        var service;

        service = {
            'getProductSearchResult': getProductSearchResult,
            'getFilterHitCount': getFilterHitCount
        };

        return service;

        /**
         * @description Method to get search results using
         * @param {String} category category
         * @param {String} [searchString] searchString
         * @param {Object[]} [searchParams] search parameters
         * @returns {Object} promise
         */
        function getProductSearchResult(category, searchString, searchParams) {
            var defer = $q.defer();
            $http({
                'method': 'GET',
                'url': $window.app.urls.searchShow,
                'params': buildParameters(category, searchString, searchParams)
            }).then(function (searchResultData) {
                $log.debug('Search successful');
                if (searchResultData.hasOwnProperty('data') && searchResultData.data !== '') {
                    defer.resolve(searchResultData.data);
                } else {
                    defer.resolve(null);
                }
            }, function () {
                defer.resolve(null);
            });

            return defer.promise;
        }

        /**
         * @description method to get filter hit count of search
         * @param {String} category category
         * @param {String} searchString searchString
         * @param {Object[]} searchParams search parameters
         * @returns {Object} promise
         */
        function getFilterHitCount(category, searchString, searchParams) {
            var defer = $q.defer();

            $http({
                'method': 'GET',
                'url': $window.app.urls.filterHitCount,
                'params': buildParameters(category, searchString, searchParams, null)
            }).then(function (filterHitCountData) {
                $log.debug('FilterHitCount successful with response:', filterHitCountData);
                if (filterHitCountData.hasOwnProperty('data') && filterHitCountData.data !== '') {
                    defer.resolve(filterHitCountData.data);
                } else {
                    defer.resolve(null);
                }
            }, function () {
                defer.resolve(null);
            });

            return defer.promise;
        }

        /**
         * @description method to build parameters object
         * @param {String} [category] category
         * @param {String} [searchString] searchString
         * @param {Object[]} [searchParams] search parameters
         * @returns {Object} params
         */
        function buildParameters(category, searchString, searchParams) {
            var params = {};

            if (category) {
                params.cgid = category;
            }
            if (searchString) {
                params.q = searchString;
            }
            if (searchParams) {
                searchParams.forEach(function (param) {
                    params[param.key] = param.value;
                });
            }

            params.format = 'ajax-page';

            return params;
        }
    }
}(angular));
