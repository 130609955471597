'use strict';

(function (angular) {
    SizeTableController.$inject = ['$scope', 'SIZE_SORTING_ORDER'];
    angular
        .module('jwsdw.product')
        .directive('jwsdwSizeTable', jwsdwSizeTable);

    /**
     * @class jwsdwProduct.jwsdwSizeTable
     * @description Size Guide
     * @returns {Object} directive object
     */
    function jwsdwSizeTable() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'sizeTable.html',
            'replace': true,
            'scope': {
                'sizeMap': '=jwsdwSizeTable',
                'type': '=jwsdwType',
                'gender': '=jwsdwGender',
                'sizesHaveLetters': '=jwsdwSizesHaveLetters',
                'showEUSizes': '=jwsdwShowEuSizes',
                'showUKSizes': '=jwsdwShowUkSizes',
                'showUSSizes': '=jwsdwShowUsSizes',
                'sizeOne': '=jwsdwSizeOne',
                'sizeTwo': '=jwsdwSizeTwo',
                'unit': '=jwsdwUnit'
            },
            'controller': SizeTableController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwSizeTable.SizeTableController
     * @description Controller Size Guide Size Table
     * @param {Object} $scope current scope
     * @param {Object} SIZE_SORTING_ORDER size ids in sorting order
     * @returns {void}
     */
    function SizeTableController($scope, SIZE_SORTING_ORDER) {
        var vm = this;

        this.$onInit = function () {
            $scope.$watchGroup(['vm.sizeOne', 'vm.sizeTwo'], function() {
                vm.sizes = [];
                if (vm.sizeOne) {
                    vm.sizes.push(vm.sizeOne);
                }
                if (vm.sizeTwo) {
                    vm.sizes.push(vm.sizeTwo);
                }

                vm.sizes = vm.sizes.sort(function(a, b) {
                    if (SIZE_SORTING_ORDER.indexOf(a.type + '-' + a.size) !== -1 &&
                        SIZE_SORTING_ORDER.indexOf(b.type + '-' + b.size) !== -1) {
                        return SIZE_SORTING_ORDER.indexOf(a.type + '-' + a.size) > SIZE_SORTING_ORDER.indexOf(b.type + '-' + b.size) ? 1 : -1;
                    }

                    // if size is not a letter, we can compare numeric values
                    return (a.type + '-' + a.size) > (b.type + '-' + b.size) ? 1 : -1;
                });
            });
        };
    }
}(angular));
