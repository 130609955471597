/* global TweenLite:true, Power2: true */
(function (angular) {
    'use strict';

    jwspPicturefill.$inject = ['$window'];
    angular
        .module('jwsp.picture')
        .directive('jwspPicturefill', jwspPicturefill);

    function jwspPicturefill($window) {
        var directive = {
            restrict: 'A',
            link: link,
            scope: {
                image: '=jwspPicturefill'
            }
        };

        return directive;

        function link(scope, element, attrs) {
            attrs.$observe('srcset', function() {
                $window.picturefill({reevaluate: true});

                if (scope.image) {
                    scope.image.isLoading = true;
                }
            });
        }
    }
}(angular));
