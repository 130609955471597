(function (angular) {
    'use strict';

    jwspMatchMedia.$inject = ['$window'];
    angular
        .module('jwsp.util')
        .directive('jwspMatchMedia', jwspMatchMedia);

    function jwspMatchMedia($window) {
        var directive = {
            restrict: 'A',
            scope: {
                viewport: '=jwspMatchMedia'
            },
            link: link
        };

        return directive;

        function link(scope, element) {
            var currentViewport = null;

            angular.element($window).on('resize', function() {
                scope.$apply(function() {
                    checkViewport();
                });
            });

            checkViewport();

            function checkViewport() {
                var beforeStyle = $window.getComputedStyle(element[0], ':after'),
                    viewport = beforeStyle.getPropertyValue('content').replace(/"/g, '');

                if (!currentViewport || currentViewport !== viewport) {
                    scope.viewport = viewport;
                }

                currentViewport = viewport;
            }
        }
    }
}(angular));
