'use strict';

(function (angular) {

    ocapiCustomerService.$inject = ['$q', '$http', '$window', '$log', 'authService'];
    angular
        .module('jwsdw.ocapi')
        .factory('ocapiCustomerService', ocapiCustomerService);

    /**
     * @class jwsdwOCAPI.ocapiCustomerService
     * @description OCAPI basket service that provides access to basket ocapi resource.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} authService ocapi auth service that provides access to authentication to the ocapi.
     * @returns {Object} service object that returns public methods
     */
    function ocapiCustomerService($q, $http, $window, $log, authService) {
        var ocapiVersion = 'v17_2',
            service;

        service = {
            'get': get,
            'update': update
        };

        return service;

        /**
         * @description Method to get a basket by basket id from the ocapi.
         * @param {String} customerId customer ID
         * @returns {Object} promise containing basket data
         */
        function get(customerId) {
            var defer = $q.defer();
            // get access token
            authService.getAccessToken().then(authSuccess, authError);

            return defer.promise;

            /**
             * Function on authentification success
             * @param {Object} response response object
             * @param {Object} response.accessToken access token
             * @returns {void}
             */
            function authSuccess(response) {
                return _getCustomer(customerId, response.accessToken).then(getCustomerSuccess, authError);

                /**
                 * Function onget basket success
                 * @param {Object} customerData basket data
                 * @returns {void}
                 */
                function getCustomerSuccess(customerData) {
                    $log.debug('jwsdw.ocapi: Customer request successful:', customerData);
                    defer.resolve(customerData.data);
                }
            }
        }


        /**
         * Merhod to update the quantity of a given product in the basket.
         * @param {Object} properties map of properties and their values to be updated
         * @returns {Object} promise containing basket data
         */
        function update(properties) {
            var defer = $q.defer();

            // get access token
            authService.getAccessToken().then(authSuccess, authError);

            return defer.promise;

            /**
             * Function on authentification success
             * @param {Object} response response object
             * @param {String} response.customerId customer id
             * @param {String} response.accessToken access token
             * @returns {void}
             */
            function authSuccess(response) {
                _updateCustomer(response.customerId, properties, response.accessToken).then(success, error);

                /**
                 * Function on success
                 * @param {Object} customerData response object
                 * @returns {void}
                 */
                function success(customerData) {
                    $log.debug('jwsdw.ocapi: Customer update successful:', customerData);
                    defer.resolve(customerData.data);
                }

                /**
                 * Function on error
                 * @param {Object} customerData response object
                 * @returns {void}
                 */
                function error(customerData) {
                    $log.debug('jwsdw.ocapi: Customer update failed:', customerData);
                    defer.reject(customerData.data);
                }
            }
        }

        /**
         * @description Method to check if auth error was an expired token error and trigger mediator to show a session error message.
         * @param {Object} errorData error data object returned with the response
         * @returns {void}
         */
        function authError(errorData) {
            if (errorData.hasOwnProperty('fault') && errorData.fault.type === 'ExpiredTokenException') {
                $window.jwsdwMediator.publish('sessionExpired');
            }
        }

        /**
         * OCAPI call to gets the basket with the given basket id.
         * @param {String} customerId customer ID
         * @param  {string} accessToken the token from the authentication that allows to access the ocapi customer resource
         * @returns {Object} promise
         * @private
         */
        function _getCustomer(customerId, accessToken) {
            $log.debug('jwsdw.ocapi: Get customer');
            return $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/customers/' + customerId,
                'headers': {
                    'Authorization': accessToken
                },
                'params': {
                    'client_id': $window.jwsdwSettings.clientId
                }
            });
        }

        /**
         * OCAPI call to gets the basket with the given basket id.
         * @param {String} customerId customer ID
         * @param {Object} params map of properties and their values to be updated
         * @param {String} accessToken the token from the authentication that allows to access the ocapi customer resource
         * @returns {Object} promise
         * @private
         */
        function _updateCustomer(customerId, params, accessToken) {
            $log.debug('jwsdw.ocapi: Update customer');
            return $http({
                'method': 'PATCH',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/customers/' + customerId,
                'data': params,
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                'params': {
                    'client_id': $window.jwsdwSettings.clientId
                }
            });
        }

    }
}(angular));