'use strict';

(function (angular) {
    productService.$inject = ['$window', '$http', '$q', '$log', 'ocapiProductService', 'LIST_PRICE_BOOK', 'SALE_PRICE_BOOK', 'BASE_URL', 'IMAGE_BASE_URL', 'SIZEGUIDE'];
    angular
        .module('jwsdw.services')
        .factory('productService', productService);

    /**
     * @class jwsdwServices.productService
     * @description Service to provide access to the product information.
     * @param {Object} $window browser window object
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} ocapiProductService service to provide access to the ocapi product resource
     * @param {String} LIST_PRICE_BOOK list price book constant from the module
     * @param {String} SALE_PRICE_BOOK sale price book constant from the module
     * @param {String} BASE_URL base url for service requests
     * @param {String} IMAGE_BASE_URL image base url constant from the module
     * @param {Object} SIZEGUIDE size guide size map with all sizes and measurements
     * @returns {Object} service object that returns public methods
     */
    function productService($window, $http, $q, $log, ocapiProductService, LIST_PRICE_BOOK, SALE_PRICE_BOOK, BASE_URL, IMAGE_BASE_URL, SIZEGUIDE) {
        var service,
            _sizeTypes = [
                'regular',
                'short',
                'tall',
                'oversize',
                'fingerGloves',
                'mittens'
            ],
            _cachedProductData = {};

        service = {
            'getProduct': getProduct,
            'getProducts': getProducts,
            'getVariants': getVariants,
            'getVariantsJSON': getVariantsJSON,
            'getAvailability': getAvailability,
            'getExpandedInformation': getExpandedInformation,
            'isOrderable': isOrderable,
            'isSaleProduct': isSaleProduct,
            'productImagePath': productImagePath,
            'productImageUrl': productImageUrl,
            'productImageAltText' : productImageAltText,
            'productVariantIds': productVariantIds,
            'productListPrice': productListPrice,
            'productSalePrice': productSalePrice,
            'addToWishlist': addToWishlist,
            'removeFromWishlist': removeFromWishlist,
            'getSizeMap': getSizeMap,
            'hasOrderableVariants': hasOrderableVariants
        };

        return service;

        /**
         * @description Method to get product data for a single product.
         * This uses the getProductData function and ensures that the correct data formats used.
         * @param {String} productId product id for which data should be returned
         * @param {String[]} expand expand response by custom attributes
         * @returns {Promise} promise with the product data for the single product
         */
        function getProduct(productId, expand) {
            var defer = $q.defer();
            getProducts([productId], expand).then(function (productData) {
                defer.resolve(productData[0]);
            }, function (unavailableProductIds) {
                defer.reject(unavailableProductIds);
            });
            return defer.promise;
        }

        /**
         * @description Method to request data of multiple products by the product Ids.
         * The data is either taken from cached product data and a consolidated OCAPI call for all uncached products.
         * @param {String[]} productIds ids for all products for which data should be returned
         * @param {String[]} expand expand response by custom attributes
         * @returns {Promise} promise containing an array of product data objects
         */
        function getProducts(productIds, expand) {
            var defer = $q.defer(),
                requestedProductData = [],
                uncachedProductIds = [],
                fragmentedProductIds,
                promises = [],
                unavailableProductIds;

            // check for each product if already in cache
            productIds.forEach(function (productId) {
                if (_cachedProductData.hasOwnProperty(productId)) {
                    requestedProductData.push(_cachedProductData[productId]);
                } else {
                    uncachedProductIds.push(productId);
                }
            });

            // get uncached products from OCAPI and add them to cache
            if (uncachedProductIds.length > 0) {
                fragmentedProductIds = _getFragmentedProductIds(uncachedProductIds);
                promises = fragmentedProductIds.map(function (productIdChunk) {
                    return ocapiProductService.getProducts(productIdChunk.join(), expand).then(function (productData) {
                        productData.forEach(function(product) {
                            _cachedProductData[product.id] = product;
                            requestedProductData.push(product);
                        });
                    });
                });
                $q.all(promises).then(function () {
                    if (requestedProductData.length === productIds.length) {
                        defer.resolve(requestedProductData);
                    } else {
                        unavailableProductIds = [
                            productIds,
                            requestedProductData.map(function(product) {
                                return product.id;
                            })
                        ].reduce(function(a, b) {
                            return a.filter(function(value) {
                                return !b.includes(value);
                            });
                        });
                        defer.reject(unavailableProductIds);
                    }
                });
            } else {
                defer.resolve(requestedProductData);
            }

            return defer.promise;
        }

        /**
         * @description Method to build the variants item with product information for all variants.
         * @param {Object} product product for which the variations are retrieved
         * @param {String[]} expand expand response by custom attributes
         * @returns {Promise} promise that contains the variants item
         * @private
         */
        function getVariants(product, expand) {
            var defer = $q.defer();

            //retrieve product information for all orderable variants and add them to the variants item
            getProducts(productVariantIds(product.variants), expand).then(function(variantsData) {
                $log.debug('variants: ', variantsData);
                defer.resolve(variantsData);
            });

            return defer.promise;
        }

        /**
         * @description Method to build the variants item with product information for all variants.
         * @param {String} productId product for which the variations are retrieved
         * @returns {Promise} promise that contains the variants item
         * @private
         */
        function getVariantsJSON(productId) {
            var defer = $q.defer();

            //retrieve product information for all orderable variants and add them to the variants item
            $http({
                'method': 'GET',
                'url': BASE_URL + '/Products-variantsJSON',
                'params': {
                    'productId': productId
                }
            }).then(function (variantsData) {
                $log.debug('variants: ', variantsData.data);
                defer.resolve(variantsData.data);
            }, function () {
                defer.resolve(null);
            });

            return defer.promise;
        }

        /**
         * @description Method to get the availability of a product.
         * @param {String} productId id of the product for which the availability is retrieved
         * @returns {Object} promise contanining the availability data.
         */
        function getAvailability(productId) {
            var defer = $q.defer();

            ocapiProductService.getAvailability(productId).then(function (availabilityData) {
                $log.debug('OCAPI call availability successful with response:', availabilityData);
                if (_cachedProductData.hasOwnProperty(productId)) {
                    _cachedProductData[productId].stock = availabilityData.data.inventory.ats;
                }
                defer.resolve(availabilityData.data);
            });

            return defer.promise;
        }

        /**
         * @description Method to request expanded data for a product.
         * @param {String} productIds ids of the producst for which the expanded information is retrieved
         * @param {String[]} expansions array of expansion parameters
         * @returns {Object} promise contanining the availability data.
         */
        function getExpandedInformation(productIds, expansions) {
            var defer = $q.defer();

            ocapiProductService.getExpandedInformation(productIds, expansions).then(function (productInformation) {
                $log.debug('OCAPI call with ' + expansions.join(',') + ' successful with response:', productInformation);
                defer.resolve(productInformation.data);
            });

            return defer.promise;
        }

        /**
         * @description Method to check if a variant of a product is orderable
         * @param {Object} product product object
         * @param {Object[]} product.variants product variants
         * @param {String} product.variants[].color product variant color
         * @param {String} product.variants[].size product variant size
         * @param {Boolean} product.variants[].orderable product is variant orderable
         * @param {String} size size of the variant that should be checked
         * @param {String} color color of the variant that should be checked
         * @returns {Boolean} true if the defined variant is orderable false otherwise
         */
        function isOrderable(product, size, color) {
            var variant;

            variant = product.variants.find(function(productVariant) {
                return productVariant.color === color && productVariant.size === size;
            });

            return variant ? variant.orderable : false;
        }

        /**
         * @description Method to check if a product is a sale product.
         * @param {Object} product product that is checked
         * @param {Object} product.prices object containing prices for respective price books
         * @returns {Boolean} true if the list price is greater than the sale or base price of the product false otherwise
         */
        function isSaleProduct(product) {
            var listPrice = productListPrice(product),
                salePrice = productSalePrice(product);

            return listPrice && salePrice && (salePrice < listPrice);
        }

        /**
         * @description Method to get the list price of a product.
         * @param {Object} product product for which the list price is retrieved
         * @param {Object} product.prices object containing prices for respective price books
         * @returns {Number | null} product list price or null if no pricebook is defined
         */
        function productListPrice(product) {
            return product.hasOwnProperty('prices') && product.prices ? product.prices[LIST_PRICE_BOOK] : null;
        }

        /**
         * @description Method to get the sale price of a product.
         * @param {Object} product product for which the sale price is retrieved
         * @param {Object} product.prices object containing prices for respective price books
         * @returns {Number | null} product sale price or null if no pricebook is defined
         */
        function productSalePrice(product) {
            return product.hasOwnProperty('prices') && product.prices ? product.prices[SALE_PRICE_BOOK] : null;
        }

        /**
         * @description Method to get product image url.
         * @param {String} imageSize the size in which the image is requested
         * @param {Object} product product for which the image should be retrieved
         * @param {String} product.id product id
         * @param {Number} product.primaryImage index for primary image
         * @param {String} format image format
         * @returns {String} image url
         */
        function productImagePath(imageSize, product, format) {
            format = format || 'jpg';
            return IMAGE_BASE_URL + '?id=' + product.id + '&width=' + imageSize + '&index=' + product.primaryImage + '&format=' + format;
        }

        /**
         * @description Method to get product image url with a specific bg color.
         * @param {String} imageWidth the width in which the image is requested
         * @param {String} imageHeight the height in which the image is requested
         * @param {Object} product product for which the image should be retrieved
         * @param {String} product.id product id
         * @param {Number} index index of the image
         * @param {String} bgColor image bg color
         * @param {String} format image format
         * @param {boolean} useFlat boolean parameter to determine whether to get the flat image
         * @returns {String} image url
         */
        function productImageUrl(imageWidth, imageHeight, product, index, bgColor, format, useFlat) {
            format = format || 'jpg';
            useFlat = useFlat || false;
            return IMAGE_BASE_URL +
                '?id=' + product.id +
                '&width=' + imageWidth +
                '&height=' + imageHeight +
                '&index=' + index +
                '&format=' + format +
                '&bgColor=' + bgColor +
                '&useFlat=' + useFlat;
        }

        /**
         * @description Method to get the image alt text for a product image if it exists.
         * @param {Object} product product for which the image alt text should be retrieved
         * @param {Object[]} product.images product images
         * @param {String} product.images[].alt image alt text
         * @returns {String | null} image alt text
         */
        function productImageAltText(product) {
            return product.hasOwnProperty('images') ? product.images[product.primaryImage].alt : null;
        }

        /**
         * @description Method to return an array of all variant ids.
         * @param {Object[]} variants all product variants
         * @returns {String[]} array containing only the variant ids
         */
        function productVariantIds(variants) {
            return variants.map(function(variant) {
                return variant.id;
            });
        }

        /**
         * @description Method to get the product ids as groups of 50 to prevent ocapi errors when retrieving product information.
         * @param {String[]} productIds array of product ids that is fragmented
         * @returns {String[]} fragmented product ids
         * @private
         */
        function _getFragmentedProductIds(productIds) {
            var i, j,
                groups = [],
                chunk = 50;
            for (i = 0, j = productIds.length; i < j; i += chunk) {
                groups.push(productIds.slice(i, i + chunk));
            }
            return groups;
        }

        /**
         * @description Method to add product to wishlist
         * @param {String} productId the product id
         * @returns {Promise} promise with the response
         */
        function addToWishlist(productId) {
            var defer = $q.defer(),
                params = {
                    'productId': productId
                };

            params[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Customer-addToWishlist',
                'params': params
            }).then(function (data) {
                defer.resolve(data.data);

            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }

        /**
         * @description Method to remove product from wishlist
         * @param {String} productListId the product list id
         * @param {String} itemId the ID of the product list item to remove
         * @returns {Promise} promise with the response
         */
        function removeFromWishlist(productListId, itemId) {
            var defer = $q.defer(),
                params = {
                    'productListId': productListId,
                    'itemId': itemId
                };

            params[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Customer-removeFromWishlist',
                'params': params
            }).then(function (data) {
                defer.resolve(data.data);

            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }

        /**
         * @description Method to build size map for given product
         * @param {Object[]} sizes size objects
         * @param {Object} product product object
         * @returns {Object} size map
         */
        function getSizeMap(sizes, product) {
            var sizeMap = SIZEGUIDE && SIZEGUIDE.originalMap[product.sizeType] ? Object.assign({}, SIZEGUIDE.originalMap[product.sizeType][product.normalizedGender]) : null,
                hasAnySizes = true;

            if (!sizeMap || sizes[0].id.indexOf('ONE SIZE') !== -1) {
                return {
                    'sizes': {
                        'regular': sizes
                    }
                };
            }

            sizeMap.sizes = _sizeTypes.reduce(function(obj, type) {
                obj[type] = _getSizes(sizeMap, type)
                    .map(function(sizeId) {
                        return sizes.find(function(size) {
                            return size.id.replace(',', '.') === sizeId;
                        });
                    })
                    .filter(function(size) {
                        return size;
                    })
                    .sort(function(a, b) {
                        var sizeA = Number(a.id.replace(',', '.')),
                            sizeB = Number(b.id.replace(',', '.'));

                        if (isNaN(sizeA) || isNaN(sizeB)) {
                            return 0;
                        }
                        return sizeA > sizeB ? 1 : -1;
                    });

                return obj;
            }, {});

            sizeMap.sizes.regular = sizeMap.sizes.regular
                .concat(sizeMap.sizes.fingerGloves)
                .concat(sizeMap.sizes.mittens)
                .concat(sizeMap.sizes.oversize)
                .filter(window.jwsdwUtil.arrayUtils.unique('id'));

            // move fingerGloves and mittens to regular as they are needed for size select
            sizeMap.sizes.fingerGloves = [];
            sizeMap.sizes.mittens = [];

            hasAnySizes = _sizeTypes.some(function(type) {
                return sizeMap.sizes[type].length > 0;
            });

            if (!hasAnySizes) {
                sizeMap.sizes.regular = sizes;
            }
            return sizeMap;
        }

        /**
         * @description Method to get sizes
         * @param {Object} sizeMap size map for types
         * @param {String} type size type (regular, short, tall, fingerGloves, mittens, default)
         * @returns {String[]} sizes
         */
        function _getSizes(sizeMap, type) {
            var sizes = [];

            if (!sizeMap.hasOwnProperty(type)) {
                return sizes;
            }

            sizes = Object.keys(sizeMap[type] || {});
            return sizes;
        }

        /**
         * @description Method to determine whether a product has any orderable variants.
         * @param {Object} product - The product to check variants for.
         * @returns {boolean} true if the product has orderable variants.
         */
        function hasOrderableVariants(product) {
            return product.variants && product.variants.some((variant) => variant.orderable);
        }
    }
}(angular));