'use strict';

(function (angular) {
    searchSuggestService.$inject = ['$q', '$log', '$window', '$http'];
    angular
        .module('jwsdw.services')
        .factory('searchSuggestService', searchSuggestService);

    /**
     * @class jwsdwServices.searchSuggestService
     * @description Service to provide access to the product information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $http http service that provides an API for ajax calls
     * @returns {Object} service object that returns public methods
     */
    function searchSuggestService($q, $log, $window, $http) {
        var service;

        service = {
            'isValidSearchString': isValidSearchString,
            'getSearchSuggestions': getSearchSuggestions
        };

        return service;

        /**
         * @description method to check if search string is valid
         * @param {String} searchString searchString
         * @returns {Boolean} true if valid
         */
        function isValidSearchString(searchString) {
            return (searchString && searchString.length > 2 && searchString.length <= 50);
        }

        /**
         * @description method to get search suggestions using ocapi search suggestion service
         * @param {String} searchString searchString
         * @returns {Object} promise
         */
        function getSearchSuggestions(searchString) {
            var defer = $q.defer();

            $http({
                'method': 'GET',
                'url': $window.app.urls.searchSuggest,
                'params': {
                    'q': searchString
                }
            }).then(function (searchSuggestionData) {
                $log.debug('SearchSuggestions successful with response:', searchSuggestionData);
                if (searchSuggestionData.hasOwnProperty('data') && searchSuggestionData.data !== "") {
                    defer.resolve(searchSuggestionData.data);
                } else {
                    defer.resolve(null);
                }
            }, function () {
                defer.resolve(null);
            });

            return defer.promise;
        }
    }
}(angular));
