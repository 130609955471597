'use strict';

(function (angular) {

    BreadcrumbController.$inject = ['$window', '$scope'];
    angular
        .module('jwsdw.breadcrumbs')
        .controller('BreadcrumbController', BreadcrumbController)
        .directive('scrollToLeft', function () {
            return {
                'restrict': 'A',
                'link': function (scope, element) {
                    // Scroll the container to the last element
                    element[0].scrollLeft = element[0].scrollWidth;
                }
            };
        });

    /**
     * @class jwsdwBreadcrumbs.BreadcrumbController
     * @description Initializes the breadcrumbs functionality
     * @param {Object} $window current window wrapped as jquery element
     * @param {Object} $scope current scope
     * @returns {void}
     */
    function BreadcrumbController($window, $scope) {
        var vm = this;
        vm.showContent = false;

        /**
         * Activate function
         * @returns {void}
         */
        this.$onInit = function () {
            vm.showContent = true;
            $window.jwsdwMediator.subscribe('updateBreadcrumbs', function (data) {
                vm.breadcrumbs = data;
                //Create a new array of breadcrumbs for PDP (viewport Mobile) with only last 3 categories from tree and remove the product's name
                if (data.length > 4) {
                    vm.breadcrumbsForMobile = data.slice(-4, -1);
                } else {
                    vm.breadcrumbsForMobile = data.slice(0, -1);
                }
                $scope.$digest();
            });
        };
    }
}(angular));