'use strict';

(function (angular) {

    ProductDetailPicker.$inject = ['$scope', '$q', '$window', '$log', '$timeout', 'productService', 'ocapiBasketService', 'SIZE_SORTING_ORDER'];
    angular
        .module('jwsdw.picker')
        .controller('ProductDetailPickerController', ProductDetailPicker);

    /**
     * @class jwsdwPicker.ProductDetailPicker
     * @description Handles the request for getting variant information and showing the respective picker
     * @param {Object} $scope current scope
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $window current window wrapped as jquery element
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $timeout timeout service provided through angular
     * @param {Object} productService service to provide product information (used for creation of variants item)
     * @param {Object} ocapiBasketService ocapi service to provide basket information
     * @param {Object} SIZE_SORTING_ORDER size ids in sorting order
     * @returns {Object} service object that returns public methods
     */
    function ProductDetailPicker($scope, $q, $window, $log, $timeout, productService, ocapiBasketService, SIZE_SORTING_ORDER) {
        var vm = this;

        vm.productService = productService;

        vm.transforms = {
            'large': {'scaleWidth': 372, 'scaleHeight': 536, 'format': 'jpg'},
            'medium': {'scaleWidth': 425, 'scaleHeight': 612, 'format': 'jpg'},
            'small': {'scaleWidth': 600, 'scaleHeight': 864, 'format': 'jpg'}
        };

        vm.currentProduct = null;
        vm.variants = null;
        vm.productDataReady = false;
        vm.productNotFound = false;
        vm.selectedSize = null;
        vm.selectedColor = null;
        vm.displayNoSizeSelectedWarning = false;
        vm.notifyMeEnabled = true;
        vm.viewport = '';

        vm.addToCart = addToCart;
        vm.addToWishlist = addToWishlist;
        vm.hasSizes = hasSizes;
        vm.showSizeGuide = true;
        vm.openSizeGuide = openSizeGuide;
        vm.openFitGuide = openFitGuide;
        vm.availabilityMessage = availabilityMessage;
        vm.customerAuthenticated = $window.jwsdwSettings.customerAuthenticated;
        vm.openNotifyMe = openNotifyMe;

        $scope.$watch(function () {
            return !vm.selectedSize || vm.selectedSize.id;
        }, function() {
            if (!vm.selectedSize || !vm.selectedColor) {
                return;
            }
            _changeProduct(vm.selectedSize.id, vm.selectedColor.id);
        });
        $scope.$watch(function () {
            return !vm.selectedColor || vm.selectedColor.id;
        }, function() {
            if (!vm.selectedSize || !vm.selectedColor) {
                return;
            }
            _changeProduct(vm.selectedSize.id, vm.selectedColor.id);
        });
        //listener for broadcasted event 'openPicker' fired in the basket controller
        window.jwsdwMediator.subscribe('openPicker', function(type, data) {
            if (type === 'productDetailPicker') {
                vm.dataService = data.dataService;
                activate(data.productId);
            }
        });
        window.jwsdwMediator.subscribe('closePicker', function(type) {
            if (type === 'productDetailPicker') {
                vm.currentProduct = null;
                vm.variants = null;
                vm.productDataReady = false;
                vm.productNotFound = false;
                vm.selectedSize = null;
                vm.selectedColor = null;
            }
        });
        window.jwsdwMediator.subscribe('changeColor', function(colorId) {
            if (colorId) {
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(vm.selectedSize ? vm.selectedSize.id : null, colorId);
                    });
                });
            }
        });
        window.jwsdwMediator.subscribe('changeSize', function(sizeId) {
            if (vm.selectedColor && sizeId) {
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(sizeId, vm.selectedColor.id);
                    });
                });
            }
        });

        /**
         * @description Method to initialize the variants, get all variant product information and show the picker.
         * @param {String} productId product Id for which variants have to be loaded
         * @returns {void}
         */
        function activate(productId) {
            vm.variants = [];

            _getProductData(productId).then(
                function (product) {
                    vm.currentProduct = product;
                    vm.refinements = [];

                    product.refinements.forEach(function(refinement) {
                        if (!refinement.values) {
                            return;
                        }
                        refinement.values.forEach(function(value) {
                            vm.refinements.push(value);
                        });
                    });

                    productService.getVariants(vm.currentProduct, ['availability', 'productRating']).then(function (variants) {
                        vm.variants = variants;
                        vm.selectedSize = null;

                        vm.variants.concat(vm.currentProduct).forEach(function(variant) {
                            variant.sizes = variant.sizes.map(function(size) {
                                var variantExists = !!_getVariant(size.id, variant.colorId);

                                size.id = size.id.replace('B', '');
                                size.value = size.value.replace('B', '');
                                size.display = variantExists;
                                return size;
                            }).sort(function(a, b) {
                                if (SIZE_SORTING_ORDER.indexOf(a.id) !== -1 &&
                                    SIZE_SORTING_ORDER.indexOf(b.id) !== -1) {
                                    return SIZE_SORTING_ORDER.indexOf(a.id) > SIZE_SORTING_ORDER.indexOf(b.id) ? 1 : -1;
                                }
                                return Number(a.id) > Number(b.id) ? 1 : -1;
                            });
                            if (variant.size) {
                                variant.size = variant.size.replace('B', '');
                            }
                            variant.sizeMap = productService.getSizeMap(variant.sizes, variant);
                        });

                        vm.selectedColor = {
                            'id': vm.currentProduct.colorId || vm.currentProduct.colors[0].id,
                            'name': vm.currentProduct.color || vm.currentProduct.colors[0].name,
                            'available': vm.currentProduct.stock > 0
                        };
                        vm.productDataReady = true;
                    });
                },
                function () {
                    vm.productNotFound = true;
                }
            );
        }

        /**
         * @description Method to check if product has sizes
         * @returns {Boolean} true if product has sizes
         * @memberOf jwsdwProductDetail.dataService
         */
        function hasSizes() {
            return Object.keys(vm.currentProduct.sizeMap.sizes).some(function(type) {
                return vm.currentProduct.sizeMap.sizes[type].length > 0;
            });
        }

        /**
         * Method broadcast to the basket that the product has been updated
         * @returns {void}
         */
        function addToCart() {
            if (!vm.selectedSize) {
                vm.displayNoSizeSelectedWarning = true;
                return;
            }

            ocapiBasketService.addViaPipeline([vm.currentProduct.id], {
                'products': [vm.currentProduct]
            });

            window.jwsdwMediator.publish('closePicker', 'productDetailPicker', true);
        }

        /**
         * @description Method to change product based on color and size IDs
         * @param {String} sizeId size ID
         * @param {String} colorId color ID
         * @returns {void}
         * @private
         */
        function _changeProduct(sizeId, colorId) {
            if (vm.variants) {
                vm.currentProduct = vm.variants.filter(function(variation) {
                    return variation.size === sizeId && variation.colorId === colorId;
                })[0] ||
                vm.variants.filter(function(variation) {
                    return variation.colorId === colorId;
                })[0] ||
                vm.currentProduct;
            }

            if (vm.currentProduct && (sizeId !== vm.currentProduct.size)) {
                vm.selectedSize = {
                    'id': vm.currentProduct.size || vm.currentProduct.sizes[0].id,
                    'available': vm.currentProduct.stock > 0
                };
                vm.showSizeGuide = vm.selectedSize.id.indexOf('ONE SIZE') === -1;
            }
        }

        /**
         * @description Method to build the variants item with product information for all variants.
         * @param {String} productId product id for which the variations are retrieved
         * @returns {Promise} promis that contains the variants item
         * @private
         */
        function _getProductData(productId) {
            var defer = $q.defer();

            //retrieve product information for all orderable variants and add them to the variants item
            productService.getProduct(productId, ['availability', 'productRating']).then(
                function(productData) {
                    $log.debug('productData: ', productData);
                    defer.resolve(productData);
                },
                function() {
                    defer.reject();
                }
            );

            return defer.promise;
        }

        /**
         * @description Method to return variation of size/color pair
         * @param {String} size Size id
         * @param {String} color Color id
         * @returns {Object} Info regarding the variant matching size and color
         * @memberOf jwsdwProductDetail.dataService
         */
        function _getVariant(size, color) {
            if (!vm.variants || vm.variants.length <= 0 || (!size && !color)) {
                return null;
            }


            if (!color) {
                return vm.variants.find(function (variant) {
                    return variant.size === size;
                });
            } else if (!size) {
                return vm.variants.find(function (variant) {
                    return variant.colorId === color;
                });
            }

            return vm.variants.find(function (variant) {
                return variant.size === size && variant.colorId === color;
            });
        }

        /**
         * @description Method to add product to wishlist
         * @param {String} productId the product id
         * @returns {void}
         * @memberOf jwsdwProductDetail.ProductDetailController
         */
        function addToWishlist(productId) {
            vm.addToWishlistLoading = true;

            productService.addToWishlist(productId).then(addToWishlistSuccess, addToWishlistError);

            /**
             * @description Method on success
             * @param {Object} data response data
             * @returns {void}
             */
            function addToWishlistSuccess(data) {
                vm.addToWishlistLoading = false;
                window.jwsdwMediator.publish('setWishlistCount', data.items);

                window.jwsdwMediator.publish('openPicker', 'wishlistPicker', {
                    'level': 2,
                    'product': {
                        'id': productId,
                        'name': vm.selectedColor.name,
                        'price': vm.productService.productSalePrice(vm.currentProduct),
                        'category': vm.currentProduct.category,
                        'size': vm.selectedSize.name,
                        'color': vm.selectedColor.name
                    }
                });
            }

            /**
             * @description Method on error
             * @returns {void}
             */
            function addToWishlistError() {
                vm.addToWishlistLoading = false;
                // error handling
            }
        }


        /**
         * @description Method to open fit guide picker
         * @returns {void}
         */
        function openFitGuide() {
            var gender = vm.currentProduct.normalizedGender,
                type = vm.currentProduct.sizeType;

            $window.jwsdwMediator.publish('openPicker', 'fitGuidePicker', {
                'level': 2,
                'gender': gender,
                'type': type,
                'availableSizes': vm.currentProduct.sizes.map(function(size) {
                    return size.id;
                })
            });
        }

        /**
         * @description Method to open size guide picker
         * @returns {void}
         */
        function openSizeGuide() {
            var gender = vm.currentProduct.normalizedGender,
                type = vm.currentProduct.sizeType;

            $window.jwsdwMediator.publish('openPicker', 'sizeGuidePicker', {
                'level': 2,
                'gender': gender,
                'type': type,
                'availableSizes': vm.currentProduct.sizes.map(function(size) {
                    return size.id;
                })
            });
        }

        /**
         * @description Method to return an availability message
         * @param {String} availabilityId the availability Id
         * @returns {String} availability message
         * @memberOf jwsdwProductDetail.dataService
         */
        function availabilityMessage(availabilityId) {
            switch (availabilityId) {
                case 'IN_STOCK':
                    return vm.currentProduct.availability.inStockMsg;
                case 'NOT_AVAILABLE':
                    return null;
                default:
                    return null;
            }
        }

        /**
         * @description Method to open notifyme
         * @returns {void}
         */
        function openNotifyMe () {
            if (!vm.selectedSize) {
                return;
            }
            $window.jwsdwMediator.publish('openPicker', 'notifyMePicker', {
                'width': 1,
                'productID': vm.currentProduct.id,
                'productName': vm.currentProduct.name,
                'productSize': vm.selectedSize.name,
                'productColor': vm.selectedColor.name,
                'image': window.jwsdwUtil.urlUtils.imageUrl(vm.currentProduct.id, 0, vm.transforms[window.jwsdwUtil.uiUtils.getViewport()], 'bone5'),
                'isVisible': true,
                'level': 2
            });

            // Script Notify Me Button Click (open)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'ce.misc',
                'eventCategory': 'MISC',
                'eventAction': 'Notify Me',
                'eventLabel': 'open',
                'eventValue': undefined,
                'nonInteraction': false,
                'selection': undefined
            });
            return true;
        }
    }
}(angular));
