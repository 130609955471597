// Conversion of svg4Everybody into angular format by Bill Chen
// this code has been edited to solve some bugs and is not the original state by Bill Chen
// https://github.com/jonathantneal/svg4everybody

(function() {
    'use strict';

    angular.module('svg-embed', [])
        .directive('svgEmbed', svgEmbed);

    svgEmbed.$inject = ['$http'];
    function svgEmbed ($http) {
        var directive = {
            restrict: 'E',
            link: link
        };

        return directive;

        function link (scope, element, attrs) {
            var svg = element.parent()[0];
            var url = attrs.xlinkHref.split('#');
            var url_root = url[0];
            var url_hash = url[1];

            if (url_root.length) {
                $http({
                    'method': 'GET',
                    'url': url_root,
                    'data': '',
                    'cache': true,
                    'headers': {
                        "Content-Type": "text/xml"
                    }
                }).then(function (data) {
                    var parser = new DOMParser()
                    var el = parser.parseFromString(data.data, "text/xml");
                    embed(svg, el.querySelector('#' + url_hash));
                });
            } else {
                embed(svg, document.getElementById(url_hash));
            }

            element.remove();
        }

        function embed(svg, g) {
            if (g) {
                var viewBox = g.getAttribute('viewBox')
                var fragment = document.createDocumentFragment()
                var clone = g.cloneNode(true);
                if (viewBox) {
                    svg.setAttribute('viewBox', viewBox);
                }

                while (clone.childNodes.length) {
                    fragment.appendChild(clone.childNodes[0]);
                }

                svg.appendChild(fragment);
            }
        }
    }
})();