/**
 * @namespace jwsdwServices
 */
'use strict';

(function (angular) {
    config.$inject = ['$compileProvider'];
    angular.module('jwsdw.services', [
        'jwsdw.ocapi',
        'ngSanitize'
    ]).config(config);

    /**
     * Config function that specifies configuration for the jwsdw services module.
     * @param {Object} $compileProvider compile provider disables adding of internal debug info.
     * @returns {void}
     */
    function config($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    }
}(angular));
