'use strict';

(function (angular) {
    jwsdwFocusSearchSmallInput.$inject = ['$document'];
    angular
        .module('jwsdw.framework')
        .directive('jwsdwFocusSearchSmallInput', jwsdwFocusSearchSmallInput);

    /**
     * @class jwsdwFramework.jwsdwFocusSearchSmallInput
     * @description Sets focus on the search input when element is clicked
     * @param {Object} $document browser document object
     * @returns {Object} directive
     */
    function jwsdwFocusSearchSmallInput($document) {
        var directive = {
            'restrict': 'A',
            'link': link
        };

        return directive;

        /**
         * @description link function
         * @param {Object} scope scope
         * @param {Object} element element
         * @param {Object} attrs attributes
         * @returns {void}
         */
        function link(scope, element, attrs) {
            element[0].addEventListener('click', function () {
                // this isn't angular best practice to get an element like this, but due to apple restrictions
                // you only can set a focus on an element right inside a click event
                // see http://stackoverflow.com/a/16601288
                var inputId = attrs.jwsdwFocusSearchSmallInput,
                    searchInput = $document[0].getElementById(inputId);

                if (searchInput) {
                    searchInput.focus();
                }
            });
        }
    }
}(angular));