'use strict';

(function (angular) {
    trackAddToBasketService.$inject = ['$window'];
    angular
        .module('jwsdw.tracking')
        .factory('trackAddToBasketService', trackAddToBasketService);

    /**
     * @class jwsdwTracking.trackAddToBasketService
     * @description Initializes the add to basket tracking functionality
     * @param {Object} $window browser window object
     * @constructor
     */
    function trackAddToBasketService($window) {
        var service = {
            'send': send,
            'sendOCAPI': sendOCAPI,
            'sendRecommendedProductImpressions': sendRecommendedProductImpressions,
            'sendAddToCartEventForRecommendedProduct': sendAddToCartEventForRecommendedProduct
        };

        return service;

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @param {Object} data General basket information and product information
         * @param {Object} data.actionArgs action args
         * @param {Object[]} data.products products to be tracked
         * @param {String} data.products[].id product id
         * @param {String} data.products[].name product name
         * @param {Number} data.products[].productPriceWithoutVAT price without VAT
         * @param {String} data.products[].productCustomType custom type
         * @param {String} data.products[].productgender product gender
         * @param {String} data.products[].division product division
         * @param {String} data.products[].sale SALR / NO SALE
         * @param {Number} data.products[].quantity product quantity
         * @returns {void}
         */
        function send(data) {
            let prods = [],
                oldLayer = window.dynamic_tm_data,
                i;

            if (!data.products) {
                return;
            }

            if (data.products.length > 1) {
                for (i = 0; i < data.products.length; i++) {
                    $window.jwsdwApi.tracking.fireEvent('addToCart', {
                        'type': 'addToCart',
                        'actionArgs': data.actionArgs,
                        'productid': data.products[i].id,
                        'productname': data.products[i].name,
                        'quantity': 1,
                        'productPriceWithoutVAT': data.products[i].productPriceWithoutVAT,
                        'productStatus': 'add',
                        'productCustomType': data.products[i].productCustomType,
                        'productgender': data.products[i].productgender,
                        'division': data.products[i].division,
                        'sale': data.products[i].sale,
                        'psize': data.products[i].size
                    });
                }
            } else {
                $window.jwsdwApi.tracking.fireEvent('addToCart', {
                    'type': 'addToCart',
                    'actionArgs': data.actionArgs,
                    'productid': data.products.map(_getKeyValue('id')).join(','),
                    'productname': data.products.map(_getKeyValue('name')).join(','),
                    'quantity': getQuantityString(data.products),
                    'productPriceWithoutVAT': data.products.map(_getKeyValue('productPriceWithoutVAT')).join(','),
                    'productStatus': 'add',
                    'productCustomType': data.products.map(_getKeyValue('productCustomType')).join(','),
                    'productgender': data.products.map(_getKeyValue('productgender')).join(','),
                    'division': data.products.map(_getKeyValue('division')).join(','),
                    'sale': data.products.map(_getKeyValue('sale')).join(','),
                    'psize': data.products.map(_getKeyValue('size')).join(',')
                });
            }

            for (i = 0; i < data.products.length; i++) {
                prods.push({
                    'name': data.products[i].name,
                    'id': data.products[i].id,
                    'price': Number(data.products[i].price),
                    'brand': data.products[i].brand || 'Jack Wolfskin GmbH & Co. KGaA',
                    'category': data.products[i].category || oldLayer.categoryid,
                    'variant': data.products[i].color,
                    'quantity': data.products[i].quantity || 1,
                    'dimension13': data.products[i].id.split('_').shift(),
                    'dimension14': data.products[i].category || oldLayer.categoryid
                });
            }

            window.dataLayer.push({
                'event':'eec.addToCart',
                'eventCategory':'ecommerce',
                'eventAction':'add to cart',
                'eventLabel': data.url ? data.url : 'cart',
                'eventValue': data.products.reduce(function(sum, prod) {
                    return sum + Number(prod.price);
                }, 0),
                'nonInteraction':false,
                'ecommerce':{
                    'currencyCode': oldLayer.currency,
                    'add':{
                        'actionField':{ 'list': 'cart' },
                        'products': prods
                    }
                }
            });
        }

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @param {Object} product product information
         * @param {Object} product.actionArgs actionArgs
         * @param {String} product.id product id
         * @param {String} product.name product name
         * @param {String} product.customType custom type
         * @param {String} product.productGender product gender
         * @param {String} product.division division
         * @param {Number} product.priceWithoutVAT price without VAT
         * @param {Number} quantity quantity
         * @returns {void}
         */
        function sendOCAPI(product, quantity) {
            let oldLayer = window.dynamic_tm_data;
            $window.jwsdwApi.tracking.fireEvent('addToCart', {
                'actionArgs': product.actionArgs,
                'type': 'addToCart',
                'productStatus': 'add',
                'productid': product.id,
                'productname': product.name,
                'productCustomType': product.customType,
                'productgender': product.productGender,
                'division': product.division,
                'productPriceWithoutVAT': product.priceWithoutVAT,
                'quantity': quantity
            });
            try {
                window.dataLayer.push({
                    'event': 'eec.addToCart',
                    'eventCategory': 'ecommerce',
                    'eventAction': 'add to cart',
                    'eventLable': 'cart',
                    'eventValue': product.price,
                    'nonInteraction': false,
                    'ecommerce': {
                        'currencyCode': oldLayer.currency,
                        'add': {
                            'actionField': { 'list': 'cart' },
                            'products': {
                                'name': product.name,
                                'id': product.id,
                                'price': product.price,
                                'brand': 'Jack Wolfskin GmbH & Co. KGaA',
                                'category': 'updated from Cart',
                                'variant': product.color,
                                'quantity': quantity,
                                'dimension13': product.id.split('_').shift(),
                                'dimension14': 'updated from Cart'
                            }
                        }
                    }
                });
            } catch (error) {
                $window.console.error('An error occurred executing tracking callback in trackAddToBasket for event: ' + 'eec.addToCart' + '. Error: ' + error);
            }
        }

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @param {Object} product product information
         * @param {Number} quantity quantity
         * @returns {void}
         */
        function sendAddToCartEventForRecommendedProduct(product, quantity) {
            let oldLayer = window.dynamic_tm_data;

            try {
                window.dataLayer.push({
                    'event': 'eec.productListImpression',
                    'eventCategory': 'ecommerce',
                    'eventAction': 'product list impression',
                    'eventLabel': 'cart-reco-care',
                    'eventValue': undefined,
                    'nonInteraction': true,
                    'ecommerce': {
                        'currencyCode': oldLayer.currency,
                        'impressions': {
                            'name': product.name,
                            'category': product.category,
                            'id': product.id,
                            'price': product.price,
                            'brand': 'Jack Wolfskin GmbH & Co. KGaA',
                            'variant': product.color,
                            'quantity': quantity,
                            'dimension13': product.id.split('_').shift(),
                            'dimension14': 'recommendation care product from Cart'
                        }
                    }
                });
            } catch (error) {
                $window.console.error('An error occurred executing tracking callback in trackAddToBasket for event: ' + 'eec.productListImpressions' + '. Error: ' + error);
            }
        }

        /**
         * @description Method to trigger tracking events based on type and passed data.
         * @param {Object} product product information
         * @param {Number} quantity quantity
         * @returns {void}
         */
        function sendRecommendedProductImpressions(product, quantity) {
            let oldLayer = window.dynamic_tm_data;

            try {
                window.dataLayer.push({
                    'event': 'eec.productListImpression',
                    'eventCategory': 'ecommerce',
                    'eventAction': 'product list impression',
                    'eventLabel': 'checkout-recommendation-care',
                    'eventValue': undefined,
                    'nonInteraction': true,
                    'ecommerce': {
                        'currencyCode': oldLayer.currency,
                        'impressions': {
                            'name': product.name,
                            'category': product.category,
                            'id': product.id,
                            'price': product.price,
                            'brand': 'Jack Wolfskin GmbH & Co. KGaA',
                            'variant': product.color,
                            'quantity': quantity,
                            'dimension13': product.id.split('_').shift(),
                            'dimension14': 'recommendation care product from Cart'
                        }
                    }
                });
            } catch (error) {
                $window.console.error('An error occurred executing tracking callback in trackAddToBasket for event: ' + 'eec.productListImpressions' + '. Error: ' + error);
            }
        }

        /**
         * @description Builds a comma separated string containing each products quantity
         * Since the quantity is currently always one, it statically writes 1 for each product
         * @param  {Object[]} products Product data
         * @returns {String} Comma separated string of product quantities
         */
        function getQuantityString(products) {
            return products.map(function() {
                return 1;
            }).join(',');
        }

        /**
         * @description Method to return curried function
         * @param {String} key key to get value for
         * @return {Function} curried function
         */
        function _getKeyValue(key) {
            return function (item) {
                return item[key];
            };
        }
    }
}(angular));