$.widget('jw.jwspTabs', {

    // default options
    'options': {
        'groupId': undefined,
        'groupIdAttr': 'data-jwsp-tabs-group',
        'contentIdAttr': 'data-jwsp-tabs-id',
        'startIndex': 0,
        'selectBoxId': undefined
    },

    '_create': function () {
        var $startTab,
            self = this;

        this.$selectBox = $('#' + this.options.selectBoxId);

        // check if groupId is defined
        if (!this.options.groupId || !this.options.groupIdAttr) {
            this.$tabs = this.element.children();
        } else {
            this.$tabs = $('[' + this.options.groupIdAttr + '=' + this.options.groupId + ']', this.element);
        }

        // initialize proxy methods
        this.clickProxy = $.proxy(this._onClick, this);

        // initialize
        this.$tabs.on('click', this.clickProxy);

        // init select box
        if (this.$selectBox.length) {
            this.$selectBox.on('change', function () {
                self._activateTab(self.$tabs.eq(this.value));
            });
        }

        // activate start tab
        $startTab = this.$tabs.eq(this.options.startIndex);
        this._activateTab($startTab);
    },

    '_onClick': function (event) {
        var $tab = $(event.target);

        // activate tab
        this._activateTab($tab);
    },

    '_activateTab': function ($tab) {
        var $siblings = this.$tabs.not($tab),
            self = this;

        // activate tab
        this._getTabContent($tab).removeClass('jws-hidden');
        $tab.addClass('is-active');

        // activate select box entry
        if (this.$selectBox.length) {
            this.$selectBox.val(this.$tabs.index($tab));
        }

        // deactivate sibling tabs
        $siblings.each(function () {
            self._deactivateTab($(this));
        });

        // trigger callback
        this._trigger('change');
    },

    '_deactivateTab': function ($tab) {
        this._getTabContent($tab).addClass('jws-hidden');
        $tab.removeClass('is-active');
    },

    '_getTabContent': function($element) {
        return $('#' + $element.attr(this.options.contentIdAttr));
    }
});