'use strict';

(function (angular) {

    PageCoverController.$inject = ['$scope', '$window', '$document', 'util'];
    angular
        .module('jwsp.pageCover')
        .controller('PageCoverController', PageCoverController);

    /**
     * @class jwspPageCover.PageCoverController
     * @description Controls the page cover overlay and functions provided through it
     * @param {Object} $scope current scope
     * @param {Object} $window current window wrapped as jquery element
     * @param {Object} $document current window.document wrapped as jquery element
     * @param {Object} util jwsp utils
     * @constructor
     */
    function PageCoverController($scope, $window, $document, util) {
        var $body = angular.element('body'),
            vm = this;

        vm.openedOverlays = [];
        vm.pageCoverClasses = [];
        vm.isPageCoverVisible = false;

        vm.closeTopOverlay = closeTopOverlay;

        this.$onInit = function () {
            $window.jwsdwMediator.subscribe('openOverlay', function (id) {
                $scope.$apply(function () {
                    openOverlay(id);
                });
            });

            $window.jwsdwMediator.subscribe('closeOverlay', function (id) {
                $scope.$apply(function () {
                    closeOverlay(id);
                });
            });

            $scope.$watch('vm.openedOverlays.length', function (newLength, oldLength) {
                vm.isPageCoverVisible = newLength > 0;

                // fills classes array that is applied to the page cover (prefixes overlay id with jws-pageCover-)
                vm.pageCoverClasses = vm.openedOverlays.map(function (id) {
                    return 'jws-pageCover-' + id;
                });

                handleEvents(newLength, oldLength);
            });
        };

        /**
         * @description Method to close the next open overlay and publish the event
         * @returns {void}
         */
        function closeTopOverlay() {
            if (vm.openedOverlays.length > 0) {
                $window.jwsdwMediator.publish('closePicker', vm.openedOverlays[vm.openedOverlays.length - 1], true);
            }
        }

        /**
         * @description Method to add opened overlay to list
         * @param {String} id string that is pushed into opened overlay list
         * @returns {void}
         */
        function openOverlay(id) {
            if (vm.openedOverlays.indexOf(id) === -1) {
                vm.openedOverlays.push(id);
                $window.jwsdwMediator.publish('pickerOpened', id);
            }
        }

        /**
         * @description Method to remove closed overlay from list
         * @param {String} id string that is removed from opened overlay list
         * @returns {void}
         */
        function closeOverlay(id) {
            var position = vm.openedOverlays.indexOf(id);
            if (position > -1) {
                vm.openedOverlays.splice(position, 1);
                $window.jwsdwMediator.publish('pickerClosed', id);
            }
        }

        /**
         * @description Method to handle events during chang of opened overlays
         * @param {Number} newLength new length of the list of opened overlays
         * @param {Number} oldLength old length of the list of opened overlays
         * @returns {void}
         */
        function handleEvents(newLength, oldLength) {
            if (newLength === 1 && oldLength === 0) {
                // add padding if scrollbar was visible to prevents content jumps when overflow-y:hidden is applied
                $body.css('paddingRight', ($window.jwsdwUtil.uiUtils.isScrollbarVisible() ? $window.jwsdwUtil.uiUtils.getScrollbarWidth($body) : 0) + 'px');
                $('.jwsdw-header-sticky').css('paddingRight', ($window.jwsdwUtil.uiUtils.isScrollbarVisible() ? $window.jwsdwUtil.uiUtils.getScrollbarWidth($body) : 0) + 'px');
                // create scroll lock when page cover is displayed
                $body.addClass('jws-overflowYHidden');
                $body.addClass('jwsdw-pickerOpen');
                // prevent scrolling on mobile iOS devices by deactivating touchmove event
                $document.on('touchmove',
                    '.jwsdw-header-module,' +
                    '.jws-pageCover,' +
                    '.jwsdw-pickerBackButton,' +
                    '.jwsdw-picker > div:not([data-jwsp-vertical-scroll]),' +
                    '.jwsdw-header-mainMenuHamburger > div:not([data-jwsp-vertical-scroll]),' +
                    '.jwsdw-header-searchSuggest > div:not([data-jwsp-vertical-scroll])', false);

                // add handler to recognize when ESC is pressed to close top overlay
                $document.on('keyup', function (event) {
                    if (util.getKeyCode(event) === 27) {
                        $scope.$apply(function() {
                            vm.closeTopOverlay();
                        });
                    }
                });
            } else if (newLength === 0) {
                // disable scroll lock and reset event handlers if page cover is not displayed anymore
                $body.removeClass('jws-overflowYHidden');
                $body.removeClass('jwsdw-pickerOpen');
                $body.css('paddingRight', 0);
                $('.jwsdw-header-sticky').css('paddingRight', 0);
                $document.off('touchmove');
                $document.off('keyup');
            }
        }
    }
}(angular));
